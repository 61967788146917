<template>
  <div class="endpoint-submenu-container">
    <div class="endpoint-submenu-wrap">
      <template v-for="(endpoint, index) in endPoints.links">
        <div class="endpoint-submenu-blocks hidden" :key="index" :id="'endpoint-submenu-'+index">
          <div
            class="endpoint-name-wrap"
            @click="collapseSection('endpoint-submenu-'+index, '.endpoint-api-wrap')"
          >
            <span class="endpoint-name">{{ endpoint.name }} Endpoints </span>
            <span class="arrow">
              <span></span>
              <span></span>
            </span>
          </div>
          <div class="endpoint-api-wrap" v-if="endpoint.linkslist && endpoint.linkslist.links">
            <div v-for="(link) in endpoint.linkslist.links" :key="link.index">
              <rz-link-item class="endpoint-api" :link="link">{{ link.name }} API</rz-link-item>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    endPoints: Object,
  },
  methods: {
    collapseSection(key, toggleClass) {
      const element = document.getElementById(key);
      if (element) {
        const contentContainer = element.querySelector(toggleClass);
        if (contentContainer) {
          if (element.classList.contains('hidden')) {
            element.classList.remove('hidden');
            contentContainer.style.height = `${contentContainer.scrollHeight}px`;
          } else {
            element.classList.add('hidden');
            contentContainer.setAttribute('style', '');
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">

.endpoint-submenu {
  &-container &-wrap &-blocks{
    .endpoint-api.link-item {
      margin-top: size(12);
      padding-left: size(10);
      @media screen and (min-width: $breakpoint-md) {
        padding: 0;
      }
      a {
        font-size: var(--rs-title-level5-font-size);
        line-height: var(--rs-title-level5-line-height);
        color: var(--regular-text);
        font-weight: normal;
        padding: 0;
      }
    }
  }
  &-container {
    z-index: var(--senior-menu-z-index);
  }
}
</style>

<style lang="scss" scoped>
.endpoint-submenu {
  &-container {
    height: auto;
    border-radius: size(0) size(0) size(2) size(2);
    box-sizing: border-box;
    @media screen and (min-width: $breakpoint-md) {
      width: 100%;
      padding: size(15);
      background-color: var(--master-bg);
      opacity: 0;
      animation: opacity-fill 200ms ease-in forwards;
      box-shadow: 0 size(4) size(5) rgba(0, 0, 0, 0.15);
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(30);
      width: size(1140);
    }
    .arrow {
      width: size(16);
      height: size(16);
      display: inline-block;
      position: absolute;
      margin: 0;
      transform: translate(20%, 15%);
      @media screen and (min-width: $breakpoint-md) {
        display: none;
      }
      span {
        top: size(8);
        position: absolute;
        width: size(10);
        height: size(2);
        background-color: var(--schema-text-color);
        display: inline-block;
        transition: all 0.4s ease;
        &:first-of-type {
          left: 0;
          transform: rotate(-45deg);
        }
        &:last-of-type {
          right: 0;
          transform: rotate(45deg);
        }
      }
    }
  }
  &-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: var(--regular-text);
    padding-left: size(10);
    padding-bottom: size(10);
    @media screen and (min-width: $breakpoint-md) {
      padding-left: 0;
      opacity: 0;
      animation: opacity-fill 400ms ease-in 300ms forwards;
    }
    .endpoint {
      &-name-wrap {
        font-size: var(--rz-para-reg-font-size);
        line-height: var(--rz-para-reg-line-height);
        font-weight: 600;
        position: relative;
        @media screen and (min-width: $breakpoint-md) {
          font-size: size(14);
          line-height: size(20);
          font-weight: bold;
          padding-left: 0;
        }
      }
      &-name {
        display: inline-block;
        min-width: size(200);
        @media screen and (min-width: $breakpoint-md) {
          min-width: unset;
        }
      }
      &-api-wrap {
        overflow: hidden;
        height: 0;
        transition: height 0.6s cubic-bezier(0.645, 0.045, 0.355, 1),
          opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        @media screen and (min-width: $breakpoint-md) {
          height: unset;
          transition: unset;
          overflow: unset;
        }
      }
    }
  }
  &-blocks {
    width: 100%;
    padding: size(10) size(0);
    &:first-child {
      padding-top: size(0);
    }
    &.hidden {
      .arrow span:first-of-type {
        transform: rotate(45deg);
        }
      .arrow span:last-of-type {
        transform: rotate(-45deg);
        }
    }
    @media screen and (min-width: $breakpoint-md) {
      width: size(150);
      padding: size(0);
      &:first-child {
        padding-top: 0;
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      width: size(230);
      &:nth-last-child(1) {
        padding-top: size(0);
      }
    }
  }
}
</style>
