<template>
  <div class="rz-jobs-v2" v-if="pageState.status.isLoaded && page">
    <div v-if="page.sections && page.sections.length">
      <section v-for="(section, index) in page.sections" :key="index">
        <section class="hero-container" v-if="section.static_section_id == 'banner'">
          <div class="wrapper">
            <div class="content-wrapper">
              <h3>{{section.name}}</h3>
              <h1>{{section.detail.items[4].name}}</h1>
              <p>{{section.detail.items[4].desc}}</p>
            </div>
            <div class="image-wrapper">
              <div class="left-container">
                <rz-image-item
                v-if="section.detail.items[0].img" :image="section.detail.items[0].img" />
              </div>
              <div class="right-container">
                <div class="container-1">
                  <rz-image-item
                    v-if="section.detail.items[1].img" :image="section.detail.items[1].img" />
                </div>
                <div class="container-2">
                  <div class="wrapper-1">
                    <rz-image-item
                    v-if="section.detail.items[2].img" :image="section.detail.items[2].img" />
                  </div>
                  <div class="wrapper-2">
                    <rz-image-item
                    v-if="section.detail.items[3].img" :image="section.detail.items[3].img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="rz-content-container" v-if="section.static_section_id == 'join-us'">
          <div class="wrapper">
            <h3>{{section.detail.name}}</h3>
            <p>{{section.detail.desc}}</p>
          </div>
        </section>
        <section class="life-at-rz" v-if="section.static_section_id == 'carousel-img'">
          <div class="wrapper">
            <div class="heading-wrapper">
              <h3>{{section.name}}</h3>
              <p>{{section.detail.desc}}</p>
            </div>
          </div>
          <div class="carousel">
            <CarouselComp :section="section" />
          </div>
        </section>
        <section class="life-at-rz content-section" v-if="section.static_section_id == 'life-at-rz'">
          <div class="wrapper" v-if="section.static_section_id == 'life-at-rz'">
            <div class="content-wrapper">
              <div v-for="item in section.detail.items" :key="item.key">
                <h4>{{item.name}}</h4>
                <p>{{item.desc}}</p>
              </div>
            </div>
          </div>
        </section>
        <section class="rz-perks" v-if="section.static_section_id == 'perks'">
          <div class="container">
            <img class="bg-img-1" src="@/assets/rz-website/wheel-small.svg" />
            <div class="wrapper">
              <div class="heading-wrapper">
                <h3>{{section.name}}</h3>
                <p>{{section.detail.desc}}</p>
              </div>
            </div>
          </div>
          <div class="wrapper">
            <div class="icons-wrapper">
              <div class="wrapper-1">
                <img class="perks-img-1" src="@/assets/rz-website/insurance.png">
                <img class="perks-img-2" src="@/assets/rz-website/learning.png">
                <img class="perks-img-3" src="@/assets/rz-website/cab.png">
              </div>
              <div class="wrapper-2">
                <img class="perks-img-4" src="@/assets/rz-website/parenting.png">
                <img  class="perks-img-5" src="@/assets/rz-website/leave.png">
                <img class="perks-img-6" src="@/assets/rz-website/food.png">
              </div>
            </div>
          </div>
          <div class="container bottom-container">
            <img class="bg-img-2" src="@/assets/rz-website/rectangle-small.svg" />
            <div class="mobile-view-design icons-wrapper">
              <div>
                <img class="perks-img-4" src="@/assets/rz-website/parenting.png">
                <img  class="perks-img-5" src="@/assets/rz-website/leave.png">
                <img class="perks-img-6" src="@/assets/rz-website/food.png">
              </div>
            </div>
            <div class="wrapper">
              <div class="content-wrapper">
                <div v-for="item in section.detail.items" :key="item.key">
                  <h4>{{item.name}}</h4>
                  <p>{{item.desc}}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="rz-contact-sales" v-if="section.static_section_id == 'onboard'">
          <sales-contact :section="section" />
        </section>
      </section>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import SalesContact from '@/components/SalesContact.vue';
import CarouselComp from '@/components/CarouselComp.vue';

export default {
  name: 'rzHome',
  components: {
    SalesContact,
    CarouselComp,
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
      rzFooterLinks: (state) => state.link.rzFooterLinks,
    }),
  },
  serverPrefetch() {
    return this.fetchData();
  },
  data() {
    return {
      articles: null,
      limit: 10,
      pagesArray: [],
      currentPage: 1,
      totalpages: null,
      pageCount: 0,
      articleLoaded: false,
      slideIndex: 0,
    };
  },
  mounted() {
    console.log('this.dataLoaded', this.dataLoaded);
    if (this.dataLoaded) {
      const sameRoute = this.page.route_value === this.$route.params.page;
      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
        return;
      }
    }

    if (!this.dataLoaded) {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      return this.fetchMenus().then(() => {
        const route = '/roanuz/pages/';
        const routeValue = `${this.$route.meta.routeValue}`;
        return this.$store.dispatch('page/fetchActivePage', { route, routeValue });
      });
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.fetchData();
    },
    fetchMenus() {
      return this.$store.dispatch('link/fetchRzFooter');
    },
  },
};
</script>
<style lang="scss">
.rz-jobs-v2 {
  .wrapper {
    max-width: size(1080);
    margin: 0 auto;
  }
  .carousel {
    overflow: hidden;
    .slideshow-container {
      position: relative;
      margin: 0 auto;
      display: flex;
      padding: size(60) size(30) 0;
      transition: transform 0.5s linear;
      .slides {
        height: size(250);
        border-radius: size(20);
        margin-right: size(20);
        display: inline-block;
        @media screen and (min-width: $breakpoint-md) {
          height: size(300);
        }
        @media screen and (min-width: $breakpoint-lg) {
          height: size(380);
        }
        img {
          height: size(250);
          border-radius: size(20);
          display: inline-block;
          @media screen and (min-width: $breakpoint-md) {
            height: size(300);
          }
          @media screen and (min-width: $breakpoint-lg) {
            height: size(380);
          }
        }
      }
    }
  }

  .btn-style {
    text-decoration: none;
    padding: size(16) size(20);
    background: var(--rz-btn-color);
    color: var(--rs-white-color);
    display: inline-block;
    border-radius: size(5);
    font-weight: bold;
    font-size: size(15);
    line-height: size(21);
    cursor: pointer;
    margin-left: size(2);
    svg {
      display: inline-block;
      width: size(4);
      height: size(8);
      fill: transparent;
      transition: margin 0.3s linear;
    }
    &:hover {
      svg {
        margin-left: size(8);
      }
    }
  }
  .rz-contact-sales {
    p {
      max-width: 100%;
    }
  }
  .hero-container {
    .left-container {
      display: none;
      @media screen and (min-width: $breakpoint-lg) {
        display: inline-block;
      }
      .image-item {
        img {
          display: none;
          @media screen and (min-width: $breakpoint-lg) {
            width: size(260);
            height: size(389);
            display: inline-block;
            border-radius: size(10);
            object-fit: cover;
          }
        }
      }
    }
    .right-container {
      .container-1 {
        display: inline-block;
        .image-item {
          img {
            width: size(340);
            height: size(200);
            display: inline-block;
            border-radius: size(10);
            object-fit: cover;
            @media screen and (min-width: $breakpoint-lg) {
              width: size(540);
              height: size(340);
            }
          }
        }
      }
      .container-2 {
        text-align: left;
        .wrapper-1 {
          display: inline-block;
          .image-item {
            img {
              width: size(130);
              height: size(180);
              display: inline-block;
              border-radius: size(10);
              object-fit: cover;
              @media screen and (min-width: $breakpoint-lg) {
                width: size(208);
                height: size(311);
              }
            }
          }
        }
        .wrapper-2 {
          display: inline-block;
          .image-item {
            img {
              width: size(180);
              height: size(230);
              display: inline-block;
              border-radius: size(10);
              object-fit: cover;
              @media screen and (min-width: $breakpoint-lg) {
                width: size(282);
                height: size(422);
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.rz-jobs-v2 {
  font-family: var(--rz-hero-font);
  color: var(--rz-copy-color);
  .wrapper {
    position: relative;
  }
  .hero-container {
    background: var(--rz-bg-color);
    padding: size(50) size(20) size(100);
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) size(120);
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(100) size(0);
    }
    .content-wrapper {
      max-width: size(500);
      @media screen and (min-width: $breakpoint-md) {
        max-width: size(320);
      }
      @media screen and (min-width: $breakpoint-lg) {
        max-width: size(500);
      }
      h3 {
        font-weight: bold;
        font-size: size(18);
        line-height: size(25);
        color: var(--regular-text-inv);
      }
      h1 {
        font-weight: 800;
        font-size: size(35);
        line-height: size(45);
        color: var(--regular-text-inv);
        padding-bottom: size(15);
        padding-top: size(15);
        @media screen and (min-width: $breakpoint-md) {
          font-size: size(50);
          line-height: size(70);
        }
        @media screen and (min-width: $breakpoint-lg) {
          font-size: size(80);
          line-height: size(90);
          padding-top: size(550);
          padding-bottom: size(20);
        }
      }
      p {
        font-size: size(18);
        line-height: size(28);
        color: var(--regular-text-inv);
      }
    }
    .image-wrapper {
      display: inline-block;
      vertical-align: top;
      padding-top: size(30);
      text-align: center;
      @media screen and (min-width: $breakpoint-md) {
        position: absolute;
        top: 0;
        right: 0;
        padding-top: 0;
        text-align: left;
      }
      @media screen and (min-width: $breakpoint-lg) {
        text-align: right;
      }
      .left-container {
        display: none;
        @media screen and (min-width: $breakpoint-lg) {
          display: inline-block;
          vertical-align: top;
          padding-right: size(40);
          padding-top: size(90);
        }
      }
      .right-container {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        @media screen and (min-width: $breakpoint-lg) {
          width: 64%;
        }
        .container-1 {
          padding-bottom: size(20);
          @media screen and (min-width: $breakpoint-lg) {
            padding-bottom: size(40);
          }
        }
        .container-2 {
          .wrapper-1 {
            padding-right: size(20);
            @media screen and (min-width: $breakpoint-lg) {
              padding-right: size(40);
            }
          }
        }
        .container-1,.container-2 {
          display: block;
        }
        .wrapper-1,.wrapper-2 {
          display: inline-block;
          vertical-align: top;
          .image-item {
            text-align: left;
          }
        }
      }
    }
  }
  .rz-content-container {
    padding: size(50) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(120) size(0) 0;
    }
    h3 {
      font-weight: bold;
      color: #9B51E0;
      padding-bottom: size(20);
      font-size: size(24);
      line-height: size(27);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(30);
        line-height: size(42);
      }
    }
    p {
      font-weight: bold;
      font-size: size(24);
      line-height: size(34);
      color: var(--rz-copy-color);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(30);
        line-height: size(42);
      }
    }
  }
  .life-at-rz {
    padding: size(50) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(120) size(0) 0;
    }
    &.content-section {
      padding-top: 0;
      padding-bottom: 0;
    }
    .heading-wrapper {
      max-width: size(600);
      text-align: left;
      h3 {
        font-weight: 800;
        font-size: size(30);
        line-height: size(37);
        color: var(--rz-copy-color);
        padding-bottom: size(20);
        @media screen and (min-width: $breakpoint-lg) {
          font-size: size(60);
          line-height: size(74);
        }
      }
      p {
        font-size: size(18);
        line-height: size(28);
        color: var(--rz-copy-color);
      }
    }
    .content-wrapper {
      padding-top: size(40);
      text-align: center;
      @media screen and (min-width: $breakpoint-md) {
        padding-top: size(10);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding-top: size(60);
      }
      div {
        display: inline-block;
        vertical-align: top;
        padding-top: size(30);
        @media screen and (min-width: $breakpoint-md) {
          width: calc((100% / 2) - #{size(30)});
          padding-right: size(35);
          padding-top: size(30);
          &:nth-child(2n) {
            padding-right: 0;
          }
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: calc((100% / 4) - #{size(30)});
          padding-right: size(40);
          padding-top: 0;
          &:nth-child(2n) {
            padding-right: size(40);
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
      h4 {
        font-weight: bold;
        font-size: size(22);
        line-height: size(32);
        padding-bottom: size(20);
        text-align: left;
      }
      p {
        font-size: size(18);
        line-height: size(28);
        text-align: left;
      }
    }
  }
  .rz-perks {
    padding: size(50) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(120) size(0) 0;
    }
    .container {
      position: relative;
      .wrapper {
        text-align: center;
      }
      .mobile-view-design {
        display: block;
        position: absolute;
        top: size(780);
        width: 100%;
        @media screen and (min-width: $breakpoint-md) {
          display: none;
        }
      }
      img {
        width: size(40);
        height: size(42);
        transition: transform 0.5s linear;
        -webkit-animation:spin 12s linear infinite;
        -moz-animation:spin 12s linear infinite;
        animation:spin 12s linear infinite;
        display: inline-block;
        @media screen and (min-width: $breakpoint-lg) {
          width: size(79);
          height: size(72);
        }
        &.bg-img-1 {
          right: size(-16);
          top: size(40);
          position: absolute;
          @media screen and (min-width: $breakpoint-md) {
            right: size(10);
            top: size(20);
          }
          @media screen and (min-width: $breakpoint-lg) {
            right: size(20);
          }
        }
        &.bg-img-2 {
          left: size(-16);
          bottom: size(16);
          position: absolute;
          @media screen and (min-width: $breakpoint-md) {
            left: size(10);
            bottom: size(16);
          }
          @media screen and (min-width: $breakpoint-lg) {
            left: size(20);
            bottom: size(110);
          }
        }
      }
      @-moz-keyframes spin {
        100% {
          -moz-transform: rotate(360deg);
        }
      }
      @-webkit-keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
        }
      }
      @keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
          transform:rotate(360deg);
        }
      }
    }
    .heading-wrapper {
      max-width: size(600);
      text-align: left;
      h3 {
        font-weight: 800;
        font-size: size(30);
        line-height: size(37);
        color: var(--rz-copy-color);
        padding-bottom: size(20);
        @media screen and (min-width: $breakpoint-lg) {
          font-size: size(60);
          line-height: size(74);
        }
      }
      p {
        font-size: size(18);
        line-height: size(28);
        color: var(--rz-copy-color);
      }
    }
    .icons-wrapper {
      margin-top: size(30);
      background: url('../assets/rz-website/path-mobile.svg') no-repeat;
      height: size(150);
      background-size: contain;
      @media screen and (min-width: $breakpoint-md) {
        height: size(175);
        margin-top: size(40);
        background: url('../assets/rz-website/path.svg') no-repeat;
        background-size: contain;
      }
      @media screen and (min-width: $breakpoint-lg) {
        background-repeat: no-repeat;
        height: size(210);
        background-size: cover;
        margin-top: size(60);
      }
      div {
        display: inline-block;
      }
      .wrapper-2 {
        display: none;
        @media screen and (min-width: $breakpoint-md) {
          display: inline-block;
        }
      }
      img {
        display: inline-block;
        width: size(75);
        height: size(74);
        margin: size(17) size(20);
        transition: transform 3s linear;
        &.perks-img-1, &.perks-img-3, &.perks-img-5 {
          animation: wave 5.5s linear infinite;
          @keyframes wave {
            0% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(#{size(30)});
              @media screen and (min-width: $breakpoint-lg) {
                transform: translateY(#{size(40)});
              }
            }
            100% {
              transform: translateY(0);
            }
          }
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: size(128);
          height: size(128);
        }
        &.perks-img-1 {
          margin: size(15);
          @media screen and (min-width: $breakpoint-md) {
            margin: size(17);
          }
          @media screen and (min-width: $breakpoint-lg) {
            margin: size(17) size(25);
          }
        }
        &.perks-img-2 , &.perks-img-2, &.perks-img-3{
          margin: size(15) size(20);
          @media screen and (min-width: $breakpoint-md) {
            margin: size(20) size(28);
          }
          @media screen and (min-width: $breakpoint-lg) {
            margin: size(17) size(30);
          }
        }
        &.perks-img-5 {
          margin-left: size(10);
          @media screen and (min-width: $breakpoint-md) {
            margin-left: size(30);
          }
          @media screen and (min-width: $breakpoint-lg) {
            margin-left: size(42);
          }
        }
        &.perks-img-2, &.perks-img-4, &.perks-img-6 {
          animation: wave-out 5.5s linear infinite;
          @keyframes wave-out {
            0% {
              transform: translateY(#{size(30)});
              @media screen and (min-width: $breakpoint-lg) {
                transform: translateY(#{size(40)});
              }
            }
            50% {
              transform: translateY(0);
            }
            100% {
              transform: translateY(#{size(30)});
              @media screen and (min-width: $breakpoint-md) {
                transform: translateY(#{size(40)});
              }
            }
          }
        }
      }
    }
    .content-wrapper {
      padding: size(30) size(15);
      @media screen and (min-width: $breakpoint-md) {
        padding: size(40);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(60);
      }
      div {
        display: inline-block;
        vertical-align: top;
        padding-bottom: size(30);
        &:nth-child(4n) {
          padding-top: size(180);
        }
        @media screen and (min-width: $breakpoint-md) {
          width: calc((100% / 2) - #{size(40)});
          padding-right: size(40);
          padding-bottom: size(30);
          &:nth-child(2n) {
            padding-right: 0;
          }
          &:nth-child(4n) {
            padding-top: 0;
          }
        }
        @media screen and (min-width: $breakpoint-lg) {
          padding-right: size(80);
          padding-top: 0;
          padding-bottom: size(56);
        }
      }
      h4 {
        font-weight: bold;
        font-size: size(22);
        line-height: size(32);
        padding-bottom: size(20);
        text-align: left;
        @media screen and (min-width: $breakpoint-lg) {
          padding-bottom: size(40);
        }
      }
      p {
        font-size: size(18);
        line-height: size(26);
        font-weight: normal;
        text-align: left;
      }
    }
  }
}

</style>
