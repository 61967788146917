<template>
    <div class="hero-section wrapper" id="container"
      @mouseover="handleMove($event, 'container')"
      @mouseout="mouseOut('container')"
      @mouseup="mouseUp('container')"
      @mousedown="mouseDown('container')"
    >
      <div class="wrapper-1" id="container-3">
        <div class="creating-text-div">
            <img src="@/assets/rz-website/Creating.svg" />
        </div>
        <div class="intelligence-text-div">
            <img src="@/assets/rz-website/Intelligence.svg" />
        </div>
      </div>
      <div class="wrapper-2" :class="'image-container-'+index"
        :id="'container-'+index"
        v-for="(item, index) in section.detail.items" :key="item.key">
            <rz-image-item
            v-if="item.img" :image="item.img" />
      </div>
      <div class="wrapper-3" id="container-4">
        <img src="@/assets/rz-website/Artificially.svg" />
      </div>
    </div>
</template>
<script>
export default {
  props: {
    section: Object,
  },
  methods: {
    handleMove(e, id) {
      if (window.matchMedia('(min-width: 1194px)')) {
        const el = document.getElementById(id);
        const height = el.clientHeight;
        const width = el.clientWidth;
        const xVal = e.layerX;
        const yVal = e.layerY;
        const yRotation = 30 * ((xVal - width / 2) / width);
        const xRotation = -30 * ((yVal - height / 2) / height);
        const string = ` scale(0.93) translate(0, 0) rotateX(${xRotation}deg) rotateY(${yRotation}deg)`;
        el.style.transform = string;
        el.style.webkitTransform = string;
        el.style.transformStyle = 'flat';
        const container1 = document.getElementById('container-3');
        const yRotation1 = 35 * ((xVal - width / 2) / width);
        const xRotation1 = -35 * ((yVal - height / 2) / height);
        const string1 = ` translate(0, 0) rotateX(${xRotation1}deg) rotateY(${yRotation1}deg)`;
        container1.style.transform = string1;
        container1.style.webkitTransform = string1;
        container1.style.transformStyle = 'flat';
        const container2 = document.getElementById('container-4');
        const yRotation2 = 45 * ((xVal - width / 2) / width);
        const xRotation2 = -45 * ((yVal - height / 2) / height);
        const string2 = ` translate(0, 0) rotateX(${xRotation2}deg) rotateY(${yRotation2}deg)`;
        container2.style.transform = string2;
        container2.style.webkitTransform = string2;
        container2.style.transformStyle = 'flat';
      }
    },
    mouseOut(id) {
      if (window.matchMedia('(min-width: 1194px)')) {
        const el = document.getElementById(id);
        el.style.transform = 'scale(1) rotateX(0) rotateY(0)';
        el.style.webkitTransform = 'scale(1) rotateX(0) rotateY(0)';
        el.style.transformStyle = 'flat';
        const container1 = document.getElementById('container-3');
        const container2 = document.getElementById('container-4');
        const string = 'translate(0, 0)';
        container1.style.transform = string;
        container2.style.transform = string;
        container1.style.webkitTransform = string;
        container2.style.webkitTransform = string;
        container1.style.transformStyle = 'flat';
        container2.style.transformStyle = 'flat';
      }
    },
    mouseDown(id) {
      if (window.matchMedia('(min-width: 1194px)')) {
        const el = document.getElementById(id);
        el.style.transform = 'scale(0.9) rotateX(0) rotateY(0)';
        el.style.webkitTransform = 'scale(0.9) rotateX(0) rotateY(0)';
        el.style.transformStyle = 'flat';
        const container1 = document.getElementById('container-3');
        const container2 = document.getElementById('container-4');
        const string = 'translate(0, 0)';
        container1.style.transform = string;
        container2.style.transform = string;
        container1.style.webkitTransform = string;
        container2.style.webkitTransform = string;
        container1.style.transformStyle = 'flat';
        container2.style.transformStyle = 'flat';
      }
    },
    mouseUp(id) {
      if (window.matchMedia('(min-width: 1194px)')) {
        const el = document.getElementById(id);
        el.style.transform = 'scale(1) rotateX(0) rotateY(0)';
        el.style.webkitTransform = 'scale(1) rotateX(0) rotateY(0)';
        el.style.transformStyle = 'flat';
        const container1 = document.getElementById('container-3');
        const container2 = document.getElementById('container-4');
        const string = 'translate(0, 0)';
        container1.style.transform = string;
        container2.style.transform = string;
        container1.style.webkitTransform = string;
        container2.style.webkitTransform = string;
        container1.style.transformStyle = 'flat';
        container2.style.transformStyle = 'flat';
      }
    },
  },

};
</script>
<style lang="scss">
.wrapper {
  max-width: size(320);
  margin: 0 auto;
  @media screen and (min-width: $breakpoint-md) {
    max-width: size(700);
  }
  @media screen and (min-width: $breakpoint-lg) {
    max-width: size(910);
  }
}
.rz-hero-container {
  .hero-section {
    perspective: 100vw;
    transition: transform 1s ease;
    -webkit-transition: transform 1s ease;
    -moz-transition: transform 1s ease;
    -o-transition: transform 1s ease;
  }
  .wrapper-1, .wrapper-3 {
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform-style: flat;
    cursor: pointer;
    transition: transform 1.2s ease;
    -webkit-transition: transform 1.2s ease;
    -moz-transition: transform 1.2s ease;
    -o-transition: transform 1.2s ease;
  }
  .wrapper-2 {
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform-style: flat;
    cursor: pointer;
    &.image-container-0 {
      position: absolute;
      top: size(96);
      z-index: 2;
      width: size(300);
      height: size(400);
      z-index: 10;
      left: 0;
      @media screen and (min-width: $breakpoint-md) {
        width: size(550);
        height: size(600);
        left: size(0);
        top: size(40);
      }
      @media screen and (min-width: $breakpoint-lg) {
        width: size(701);
        height: size(700);
        top: size(80);
      }
      .image-item {
        transition: transform 0.5s linear;
        -webkit-transition: transform 0.5s linear;
        -moz-transition: transform 0.5s linear;
        -o-transition: transform 0.5s linear;
        cursor: pointer;
        img {
          display: inline-block;
          width: size(300);
          height: size(400);
          object-fit: contain;
          @media screen and (min-width: $breakpoint-md) {
            width: size(550);
            height: size(600);
          }
          @media screen and (min-width: $breakpoint-lg) {
            width: size(701);
            height: size(700);
          }
        }
      }
    }
    &.image-container-1 {
      position: absolute;
      top: size(245);
      z-index: 2;
      right: size(50);
      width: size(40);
      height: size(40);
      z-index: 10;
      @media screen and (min-width: $breakpoint-md) {
        right: size(178);
        width: size(75);
        height: size(75);
        top: size(250);
      }
      @media screen and (min-width: $breakpoint-lg) {
        right: size(200);
        top: size(350);
        width: size(100);
        height: size(100);
      }
      .image-item {
        img {
          display: inline-block;
          width: size(40);
          height: size(40);
          object-fit: contain;
          @media screen and (min-width: $breakpoint-md) {
            width: size(90);
            height: size(90);
          }
        }
      }
    }
  }
  .wrapper-1 {
    position: absolute;
    z-index: 5;
    top: size(40);
    @media screen and (min-width: $breakpoint-lg) {
      top: size(70);
    }
    .creating-text-div {
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(20);
      }
      img {
        display: inline-block;
        width: size(232);
        height: size(80);
        @media screen and (min-width: $breakpoint-md) {
          width: size(350);
          height: size(120);
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: size(451);
          height: size(140);
        }
      }
    }
    .intelligence-text-div {
      padding-left: size(10);
      padding-bottom: size(120);
      @media screen and (min-width: $breakpoint-md) {
        padding-left: size(250);
        padding-bottom: size(285);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding-left: size(300);
        padding-bottom: size(360);
      }
      img {
        display: inline-block;
        width: size(312);
        height: size(80);
        @media screen and (min-width: $breakpoint-md) {
          width: size(450);
          height: size(120);
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: size(607);
          height: size(140);
        }
      }
    }
  }
  .hero-section {
    position: relative;
    height: size(430);
    @media screen and (min-width: $breakpoint-md) {
      height: size(630);
    }
    @media screen and (min-width: $breakpoint-lg) {
      height: size(750);
    }
  }
  .wrapper-3 {
    position: absolute;
    z-index: 15;
    bottom: 0;
    left: size(30);
    bottom: size(40);
    @media screen and (min-width: $breakpoint-md) {
      bottom: size(120);
    }
    img {
      display: inline-block;
      width: size(270);
      height: size(80);
      @media screen and (min-width: $breakpoint-md) {
        width: size(450);
        height: size(120);
      }
      @media screen and (min-width: $breakpoint-lg) {
        width: size(550);
        height: size(140);
      }
    }
  }
}
</style>
