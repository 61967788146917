<template>
  <div class="rz-about-us" v-if="pageState.status.isLoaded && page">
    <div v-if="page.sections && page.sections.length">
      <div class="hero-section">
        <div class="common-wrapper">
          <h1>{{page.brief}}</h1>
        </div>
      </div>
      <section v-for="(section, index) in page.sections" :key="index">
        <section class="rz-founders-container" v-if="section.static_section_id == 'founders'" >
          <div class="common-wrapper">
            <div class="wrapper-container">
              <div class="founder-text"></div>
              <div class="content-wrapper">
                <div :class="'container-'+ index"
                v-for="(item, index) in section.detail.items" :key="item.key">
                  <rz-image-item
                      v-if="item.img" :image="item.img" />
                  <h3>{{item.name}}</h3>
                  <p>{{item.desc}}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="rz-mission-container" v-if="section.static_section_id == 'mission'">
          <div class="mission common-wrapper">
            <p class="wrapper">{{section.detail.desc}}</p>
          </div>
        </section>
        <section class="rz-numbers-container" v-if="section.static_section_id == 'numbers'">
          <NumberAnimationComp :section="section"/>
        </section>
        <section class="rz-customers-container"  v-if="section.static_section_id == 'customer_logos'">
          <div class="common-wrapper">
            <p class="wrapper">{{section.detail.desc}}</p>
            <rz-image-item
              v-if="section.detail.img" :image="section.detail.img" />
          </div>
        </section>
        <section class="rz-press-mentions"  v-if="section.static_section_id == 'press_mentions'">
          <div class="common-wrapper">
            <h3>Press Mentions</h3>
            <div class="news-wrapper">
              <div v-for="(item, index) in section.detail.items" :key="item.key">
                <img :src="item.img.url" :alt="item.img.alt" :class="'img-'+index" />
                <p>{{item.desc}}</p>
                <a :href="item.links[0].url">
                  {{item.links[0].name}}
                  <svg><use v-bind="{'xlink:href':'#home-arrow-blue-1'}"></use></svg></a>
              </div>
            </div>
          </div>
        </section>
        <section class="rz-content-container" v-if="section.static_section_id == 'social_proof'">
          <div class="wrapper">
            <rz-markdown :text="section.detail.desc"></rz-markdown>
          </div>
        </section>
        <section class="rz-join-us" v-if="section.static_section_id == 'openings'">
          <join-us-section :section="section"/>
        </section>
        <section class="rz-pre-footer" v-if="section.static_section_id == 'pre_footer'">
          <div class="common-wrapper">
            <pre-footer :section="section"/>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import PreFooter from '@/components/PreFooter.vue';
import JoinUsSection from '@/components/JoinUsSection.vue';
import NumberAnimationComp from '@/components/NumberAnimationComp.vue';

export default {
  name: 'rzHome',
  components: {
    PreFooter,
    JoinUsSection,
    NumberAnimationComp,
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
    }),
  },
  serverPrefetch() {
    return this.fetchData();
  },
  mounted() {
    console.log('this.dataLoaded', this.dataLoaded);
    if (this.dataLoaded) {
      const sameRoute = this.page.route_value === this.$route.meta.routeValue;
      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
        return;
      }
    }

    if (!this.dataLoaded) {
      this.freshFetch();
    }
  },
  methods: {
    fetchData() {
      const route = '/roanuz/pages/';
      const routeValue = `${this.$route.meta.routeValue}`;
      return this.$store.dispatch('page/fetchActivePage', { route, routeValue });
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
    },
    freshFetch() {
      return this.fetchData();
    },
  },
};
</script>
<style lang="scss">
.rz-about-us {
  .common-wrapper {
    max-width: size(1080);
    margin: 0 auto;
  }
  .rz-pre-footer {
    padding: size(40) size(20) 0;
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(60) size(0) 0;
    }
  }
  .wrapper {
    max-width: size(920);
    margin: 0;
  }
   .rz-numbers-container {
    padding: size(50) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
     padding: size(120) size(0) 0;
    }
    .wrapper {
      margin: 0;
    }
    h3 {
      font-weight: bold;
      font-size: size(24);
      line-height: size(27);
      color: var(--rz-copy-color);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(30);
        line-height: size(42);
      }
    }
    .number-container {
      max-width: size(240);
      display: inline-block;
      vertical-align: top;
      margin-right: size(50);
      width: calc((100% / 2) - #{size(40)});
      padding-top: size(20);
      &:nth-child(2n) {
        margin-right: 0;
      }
      @media screen and (min-width: $breakpoint-md) {
        &:nth-child(2n) {
          margin-right: size(50);
        }
      }
      @media screen and (min-width: $breakpoint-lg) {
        width: calc((100% / 3) - #{size(40)});
        padding-top: size(40);
        margin-right: size(80);
        &:nth-child(2n) {
          margin-right: size(80);
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
      .number {
        display: block;
        font-size: size(35);
        line-height: size(42);
        padding-bottom: size(5);
        font-weight: 800;
        border-bottom: size(2) solid var(--rz-copy-color);
        @media screen and (min-width: $breakpoint-lg) {
          font-size: size(60);
          line-height: size(74);
        }
      }
      .desc {
        display: inline-block;
        font-size: size(18);
        line-height: size(28);
        padding-top: size(20);
      }
    }
  }
  .btn-style {
    text-decoration: none;
    padding: size(16) size(20);
    background: var(--rz-btn-color);
    color: var(--rs-white-color);
    display: inline-block;
    border-radius: size(5);
    font-weight: bold;
    font-size: size(15);
    line-height: size(21);
    cursor: pointer;
    margin-left: size(2);
    svg {
      display: inline-block;
      width: size(8);
      height: size(14);
      vertical-align: top;
      fill: transparent;
      margin-left: size(5);
      transition: transform 0.3s linear;
    }
    &:hover {
      svg {
        transform: translate(#{size(4)});
      }
    }
  }
  .rz-founders-container {
    .image-item {
      height: size(160);
      @media screen and (min-width: $breakpoint-md) {
        height: size(240);
      }
    }
  }
  .rz-content-container {
    .wrapper {
      margin: 0 auto;
      max-width: size(960);
    }
    .markdown {
      display: inline-block;
      font-size: size(35);
      line-height: size(42);
      padding-bottom: size(5);
      font-weight: 800;
      padding: size(20);
      background: url('../../src/assets/rz-website/quotes.svg') no-repeat;
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(60);
        line-height: size(74);
        padding: size(20);
      }
      em {
        color: #9B51E0;
      }
    }
  }
  .rz-customers-container {
    .image-item {
      padding-top: size(40);
      height: size(100);
      @media screen and (min-width: $breakpoint-md) {
        height: size(180);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding-top: size(60);
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.rz-about-us {
  font-family: var(--rz-hero-font);
  color: var(--rz-copy-color);
  .hero-section {
    background: #8E2E42;
    padding: size(50) size(20);
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20);
    }
    @media screen and (min-width: $breakpoint-lg) {
     padding: size(120) size(0);
    }
    h1 {
      font-size: size(30);
      line-height: size(42);
      color: var(--regular-text-inv);
      font-weight: 800;
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(60);
        line-height: size(74);
      }
    }
  }
  .rz-founders-container {
    padding: size(50) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
     padding: size(80) size(0) 0;
    }
    .wrapper-container {
      .founder-text {
        height: size(75);
        background-image: url('../../src/assets/rz-website/founders.svg');
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: size(200);
        padding-bottom: size(30);
        @media screen and (min-width: $breakpoint-md) {
          height: size(100);
          background-size: size(500);
        }
        @media screen and (min-width: $breakpoint-lg) {
          height: size(200);
          background-size: size(800);
        }
      }
      h3 {
        font-weight: bold;
        font-size: size(18);
        line-height: size(25);
        padding-bottom: size(4);
        padding-top: size(10);
      }
      p {
        font-weight: normal;
        font-size: size(15);
        line-height: size(21);
      }
      .content-wrapper {
        display: flex;
        justify-content: space-around;
        max-width: size(450);
        margin: 0 auto;
        .container-1 {
          margin-top: size(60);
        }
      }
    }
  }
  .rz-mission-container {
    padding: size(50) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
     padding: size(120) size(0) 0;
    }
    p {
      font-weight: 800;
      font-size: size(30);
      line-height: size(42);
      color: var(--rz-copy-color);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(60);
        line-height: size(74);
      }
    }
  }
  .rz-customers-container {
    padding: size(50) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
     padding: size(140) size(0) 0;
    }
    p {
      font-size: size(35);
      line-height: size(42);
      padding-bottom: size(5);
      font-weight: 800;
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(60);
        line-height: size(74);
      }
    }
  }
  .rz-content-container {
    padding: size(50) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
     padding: size(140) size(0) 0;
    }
  }
  .rz-press-mentions {
    padding: size(50) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
     padding: size(140) size(0) 0;
    }
    h3 {
      font-weight: bold;
      font-size: size(24);
      line-height: size(27);
      color: var(--rz-copy-color);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(30);
        line-height: size(42);
        padding-bottom: size(40);
        padding-bottom: size(30);
      }
    }
    .news-wrapper {
      text-align: center;
      @media screen and (min-width: $breakpoint-lg) {
        text-align: left;
      }
      div {
        padding: size(30);
        border: size(1) solid #DADADA;
        border-radius: size(10);
        max-width: size(268);
        display: inline-block;
        vertical-align: top;
        margin-top: size(20);
        text-align: left;
        @media screen and (min-width: $breakpoint-md) {
          margin-right: size(30);
          margin-top: size(30);
          height: size(210);
        }
        @media screen and (min-width: $breakpoint-lg) {
          margin-right: 0;
          margin-top: 0;
          &:nth-child(2n) {
            margin: 0 size(40);
            height: auto;
          }
        }
        img {
          margin-bottom: size(20);
          text-align: left;
          &.img-0 {
            width: size(118);
            height: size(25);
          }
          &.img-1 {
            width: size(184);
            height: size(20);
          }
          &.img-2 {
            width: size(88);
            height: size(25);
          }
        }
        h4 {
          font-size: size(18);
          line-height: size(24);
          padding-bottom: size(20);
          font-weight: bold;
          text-align: left;
        }
        p {
          font-weight: normal;
          font-size: size(18);
          line-height: size(28);
          color: var(--rz-copy-color);
          padding-bottom: size(30);
          text-align: left;
        }
        a {
          text-decoration: none;
          font-weight: bold;
          font-size: size(15);
          line-height: size(21);
          color: var(--rz-copy-color);
          display: inline-block;
          text-align: left;
          cursor: pointer;
          svg {
            display: inline-block;
            width: size(8);
            height: size(8);
            fill: transparent;
            stroke: var(--rz-copy-color);
            margin-left: size(5);
            transition: transform 0.3s linear;
          }
          &:hover {
            svg {
              transform: translate(#{size(4)});
            }
          }
        }
      }
    }
  }
}
</style>
