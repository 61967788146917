<template>
  <div :class="[section.static_section_id.split('_').join('-')]" class="promo">
    <div class="rs-container">
      <div class="inner-wrapper banner-wrapper">
        <div class="content-wrapper">
          <h6 :class="[section.detail.links[1].element_class === 'text' ? '': 'flag-text']"
            v-if="section.detail.links && section.detail.links[1] && section.detail.links[1].name">
            {{section.detail.links[1].name}}</h6>
          <rz-markdown class="hero-title" v-if="section.detail.name"
            :text="section.detail.name"></rz-markdown>
          <rz-markdown v-if="section.detail.desc" class="text" :text="section.detail.desc"></rz-markdown>
          <TextButton :buttonType="'rs-button'" :key="section.detail.links[0].name"
            :link="section.detail.links[0]" />
        </div>
        <div class="image-wrapper">
          <rz-image-item class="banner-artwork"
            v-if="section.detail.img" :image="section.detail.img" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TextButton from '@/components/Shared/TextButton.vue';

export default {
  props: {
    section: Object,
  },
  components: {
    TextButton,
  },
};
</script>
<style lang="scss">
.banner-wrapper {
  .content-wrapper {
    .hero-title {
      display: inline-block;
      font-weight: 800;
      font-size: size(30);
      line-height: size(42);
      padding-bottom: size(10);
      padding-right: 0;
      color: #97E6AB;
      @media screen and (min-width: $breakpoint-md) {
        font-size: size(60);
        line-height: size(74);
      }
      strong {
        font-weight: bold;
        font-size: size(30);
        line-height: size(42);
        padding-right: 0;
        color: var(--rs-white-color);
        padding-bottom: 0;
        @media screen and (min-width: $breakpoint-md) {
          font-size: size(60);
          line-height: size(74);
        }
      }
    }
    .text {
      .p {
        font-size: size(16);
        line-height: size(24);
        color: var(--rs-white-color);
        padding-bottom: size(30);
      }
    }
    .text-button.rs-button .link {
      padding: 0;
      margin: 0;
      color: var(--rs-white-color);
      border: none;
      outline: none;
      &:after {
        border-color: var(--rs-white-color);
        margin-left: size(10);
      }
    }
  }
}

.ants-promo {
  &.promo {
    .rs-container {
      padding-bottom: 0;
      .inner-wrapper {
        position: relative;
        background-color: #010101;
        border-radius: size(6);
        padding: size(30) size(20);
        @media screen and (min-width: $breakpoint-md) {
          padding: size(40) size(20);
        }
        @media screen and (min-width: $breakpoint-lg) {
          padding: size(60);
        }
        .flag-text {
          clip-path: none;
          background: none;
          width: auto;
          margin: 0 0 size(10);
          padding: 0;
          color: #D0F37B;
          font-size: size(16);
          font-weight: 700;
          line-height: normal;
          text-transform: capitalize;
          display: inline-block;
          @media screen and (min-width: size(1024)) {
            font-size: size(24);
          }
        }
        .hero-title {
          display: block;
          .p {
            color: var(--rs-white-color);
            font-size: size(20);
            line-height: size(28);
            @media screen and (min-width: $breakpoint-lg) {
              font-size: size(40);
              line-height: size(50);
            }
          }
        }
        .text-button.rs-button .link {
          padding: 0;
          margin: 0;
          color: var(--rs-white-color);
          border: none;
          outline: none;
          &:after {
            border-color: var(--rs-white-color);
            margin-left: size(10);
          }
        }
        .image-wrapper {
          @media screen and (min-width: $breakpoint-md) {
            position: absolute;
            right: 0;
            bottom: 0;
            height: size(365);
            padding-left: 0;
            height: 100%;
          }
          @media screen and (min-width: size(1024)) {
            height: size(300);
          }
          @media screen and (min-width: $breakpoint-md) {
            height: 100%;
          }

          .banner-artwork {
            width: 98%;
            @media screen and (min-width: size(1024)) {
              width: size(600);
            }
            @media screen and (min-width: $breakpoint-lg) {
              width: size(464);
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.banner-wrapper {
  .content-wrapper {
    display: inline-block;
    vertical-align: top;
    @media screen and (min-width: $breakpoint-md) {
      width: 50%;
    }
    @media screen and (min-width: $breakpoint-lg) {
      width: size(440);
    }
  }
  .image-wrapper {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    padding-top: size(30);
    width: 100%;
    height: size(350);
    @media screen and (min-width: $breakpoint-md) {
      padding-left: size(30);
      width: 45%;
      padding-top: size(75);
      height: size(400);
    }
    @media screen and (min-width: size(1024)) {
      padding-top: 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding-left: size(50);
      width: auto;
    }
    .banner-artwork {
      display: inline-block;
      vertical-align: bottom;
      @media screen and (min-width: $breakpoint-md) {
        width: 100%;
      }
      @media screen and (min-width: $breakpoint-lg) {
        width: size(420);
      }
    }
  }
}
</style>
