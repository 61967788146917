<template>
  <div class="common-wrapper">
    <h3>{{section.detail.name}}</h3>
    <div class="wrapper">
        <div class="number-container" v-for="(item, index) in section.detail.links" :key="item.key">
        <span class="number" :id="'value-'+index">{{item.name}}</span>
        <span class="desc">{{item.desc}}</span>
        </div>
    </div>
    <p class="footnote">Updated on Aug 2021</p>
  </div>
</template>
<script>
export default {
  props: {
    section: Object,
  },
  mounted() {
    this.animateValue('value-0', 1, 5000);
    this.animateValue('value-1', 1, 5000);
    this.animateValue('value-2', 1, 5000);
    this.animateValue('value-3', 0, 5000);
    this.animateValue('value-4', 1, 5000);
    this.animateValue('value-5', 1, 5000);
  },
  methods: {
    animateValue(id, start, duration) {
      const val = document.getElementById(id).innerHTML;
      let end = parseFloat(val);
      let decimal = end.toString();
      const num = decimal.split('.')[1];
      if (decimal.indexOf('.')) {
        // eslint-disable-next-line prefer-destructuring
        decimal = decimal.split('.')[0];
        end = parseInt(decimal, 10);
      }
      if (start === end) return;
      const range = end - start;
      let current = start;
      const increment = end > start ? 1 : -1;
      let stepTime = Math.abs(Math.floor(duration / range));
      const obj = document.getElementById(id);
      const timer = setInterval(() => {
        if (id === 'value-2') {
          stepTime = duration / range;
          current += increment;
          obj.innerHTML = `${current}k`;
        } else if (id === 'value-3' || id === 'value-4' || id === 'value-5') {
          current += increment;
          obj.innerHTML = current;
          stepTime = Math.abs(Math.floor(duration / range));
          if (id === 'value-3') {
            obj.innerHTML = `${current}.${num} B`;
          } else {
            obj.innerHTML = `${current}.${num} M`;
          }
        } else {
          current += increment;
          obj.innerHTML = current;
        }
        if (current === end) {
          clearInterval(timer);
        }
      }, stepTime);
    },
  },
};
</script>
<style lang="scss" scoped>
.common-wrapper {
  .footnote {
    padding-top: size(40);
    font-size: size(12);
    color: grey;
  }
}
</style>
