<template>
  <div class="rz-contact-us" v-if="pageState.status.isLoaded && page">
    <div v-if="page.sections && page.sections.length">
      <section v-for="(section, index) in page.sections" :key="index">
        <section class="banner-container" v-if="section.static_section_id == 'banner'" >
          <ContactUsBanner :section="section" />
        </section>
        <section class="address-container" v-if="section.static_section_id == 'address'">
          <div class="common-wrapper">
            <div v-for="item in section.detail.items" :key="item.key">
              <h4>{{item.name}}</h4>
              <p>{{item.desc}}</p>
              <a v-if="item.links[0]" :href="item.links[0].url">
                {{item.links[0].name}} <svg><use v-bind="{'xlink:href':'#home-arrow'}"></use></svg></a>
            </div>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import ContactUsBanner from '@/components/ContactUsBanner.vue';

export default {
  name: 'rzHome',
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
    }),
  },
  components: {
    ContactUsBanner,
  },
  serverPrefetch() {
    return this.fetchData();
  },
  mounted() {
    console.log('this.dataLoaded', this.dataLoaded);
    if (this.dataLoaded) {
      const sameRoute = this.page.route_value === this.$route.meta.routeValue;
      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
        return;
      }
    }

    if (!this.dataLoaded) {
      this.freshFetch();
    }
  },
  methods: {
    fetchData() {
      const route = '/roanuz/pages/';
      const routeValue = `${this.$route.meta.routeValue}`;
      return this.$store.dispatch('page/fetchActivePage', { route, routeValue });
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
    },
    freshFetch() {
      return this.fetchData();
    },
  },
};
</script>
<style lang="scss">
.rz-contact-us {
  .common-wrapper {
    max-width: size(1080);
    margin: 0 auto;
  }
  .wrapper {
    max-width: size(920);
  }
  .btn-style {
    text-decoration: none;
    padding: size(16) size(20);
    background: var(--rz-btn-color);
    color: var(--rs-white-color);
    display: inline-block;
    border-radius: size(5);
    font-weight: bold;
    font-size: size(15);
    line-height: size(21);
    cursor: pointer;
    margin-left: size(2);
    svg {
      display: inline-block;
      width: size(4);
      height: size(8);
      fill: transparent;
      transition: margin 0.3s linear;
    }
    &:hover {
      svg {
        margin-left: size(8);
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.rz-contact-us {
  font-family: var(--rz-hero-font);
  color: var(--rz-copy-color);
  .address-container {
    background: #030303;
    padding: size(40) size(20);
    @media screen and (min-width: $breakpoint-lg) {
     padding: size(60) size(0);
    }
    .common-wrapper {
      text-align: center;
      @media screen and (min-width: $breakpoint-lg) {
        text-align: left;
      }
      div {
        display: inline-block;
        vertical-align: top;
        max-width: size(300);
        padding-top: size(20);
        @media screen and (min-width: $breakpoint-lg) {
          padding-top: 0;
          &:nth-child(2n) {
            padding: 0 size(90);
          }
        }
      }
      h4 {
        font-size: size(15);
        line-height: size(21);
        text-transform: uppercase;
        color: #03B4D7;
        padding-bottom: size(20);
      }
      p {
        font-size: size(22);
        line-height: size(32);
        color: var(--regular-text-inv);
        padding-bottom: size(20);
      }
      a {
        text-decoration: none;
        font-size: size(15);
        line-height: size(21);
        color: var(--regular-text-inv);
        margin-left: size(2);
        cursor: pointer;
        svg {
          display: inline-block;
          width: size(8);
          height: size(8);
          transition: margin 0.3s linear;
        }
        &:hover {
          svg {
            margin-left: size(8);
          }
        }
      }
    }
  }
}
</style>
