<template>
  <div class="rs-blog-list">
    <div v-if="blogLoaded">
      <div class="hero-section">
        <h1>Newsroom</h1>
      </div>
      <div class="container">
        <BlogListTwoCol :blog="blogList.blogs[0]" />
        <div>
          <div class="blog-list">
            <template v-for="(blog, index) in blogList.blogs">
              <div class="list-wrap" :key="index" v-if="index !== 0">
                <div class="list" :class="'list'+index">
                  <router-link
                    :to="{
                      name: 'rsBlogDetailView',
                      params: {blogid: blog.route_value.replace('rs-cricket-', '')}
                    }"
                  >
                    <div class="image-wrap" v-if="blog.card_img && blog.card_img.url">
                      <rz-image-item :image="blog.card_img" />
                    </div>
                    <div class="image-wrap" v-else>
                      <img class="image-item" src="@/assets/blog-placeholder.png" alt="gallery" />
                    </div>
                    <div class="title" v-if="blog.category">{{ blog.category}}</div>
                    <div class="desc" v-if="blog.card_title">{{ blog.card_title }}</div>
                    <div
                      class="date"
                      v-if="blog.publication_date"
                    >{{ formatBlogPublicationDate(blog.publication_date)}}</div>
                  </router-link>
                </div>
              </div>
            </template>
            <div
              class="list-wrap on-tab"
              v-for="(emptyBlogs, index) in addEmptyBlogs"
              :key="index+'emptyblog'"
            ></div>
          </div>
        </div>
        <TextButton class="archive-btn" :buttonType="'rs-button'" :link="archiveRoute" />
      </div>
      <div v-for="(section) in page.sections" :key="section.static_section_id">
        <section class="rz-contact-sales" v-if="section.static_section_id == 'onboard'">
          <sales-contact :section="section" />
        </section>
        <section class="rz-pre-footer" v-if="section.static_section_id == 'pre_footer'">
          <div class="common-wrapper">
            <pre-footer :section="section"/>
          </div>
        </section>
      </div>
    </div>
    <div class="content placeholder" v-else>
      <LoadingPlaceholder :state="pageState" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LoadingPlaceholder from '@/components/Shared/LoadingPlaceholder.vue';
import BlogListTwoCol from '@/components/BlogListTwoColSec.vue';
import SalesContact from '@/components/SalesContact.vue';
import PreFooter from '@/components/PreFooter.vue';
import TextButton from '@/components/Shared/TextButton.vue';

export default {
  name: 'BlogList',
  components: {
    LoadingPlaceholder,
    BlogListTwoCol,
    TextButton,
    SalesContact,
    PreFooter,
  },
  computed: {
    ...mapState({
      blogLoaded: (state) => state.blog.activeBlogListState.status.isLoaded,
      blogListState: (state) => state.blog.activeBlogListState,
      blogList: (state) => state.blog.activeBlogList,
      page: (state) => state.page.activePage,
      pageState: (state) => state.page.activePageState,
      pageLoaded: (state) => state.page.activePageState.status.isLoaded,
    }),
    addEmptyBlogs() {
      if (this.blogList.blogs) {
        switch ((this.blogList.blogs.length - 1) % 3) {
          case 1:
            return 2;

          case 2:
            return 1;

          default:
            return 0;
        }
      }
      return 0;
    },
  },
  serverPrefetch() {
    return this.freshFetch();
  },
  data() {
    return {
      blogListLimit: 13,
      archiveRoute: {
        routeParams: { name: 'rsBlogArchives' },
        name: 'View Archive',
      },
    };
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        console.log('Refreshing page because route change');
        this.refreshPage();
      }
    },
  },
  mounted() {
    if (this.blogLoaded) {
      // eslint-disable-next-line operator-linebreak
      const sameRoute = this.page.routeValue === this.$route.meta.routeValue;
      if (!sameRoute) {
        console.log(
          'Refreshing page because page mounted with different route',
        );
        this.refreshPage();
        return;
      }
    }

    if (!this.blogLoaded) {
      this.freshFetch();
    }
  },
  methods: {
    freshFetch() {
      return Promise.all([
        this.fetchBlogs(),
        this.fetchBannerData(),
      ]);
    },
    fetchBannerData() {
      return this.fetchMenus().then(() => {
        const route = '/roanuz/pages/';
        const routeValue = 'newsroom';
        console.log('route value', this.$route.meta.routeValue);
        return this.$store.dispatch('page/fetchActivePage', {
          route,
          routeValue,
        });
      });
    },
    fetchBlogs() {
      const params = {
        limit: this.blogListLimit,
      };
      return this.$store.dispatch('blog/fetchBlogList', {
        params,
        routeName: 'bloglist',
      });
    },
    refreshPage() {
      this.$store.commit('blog/resetActivePage');
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
    },
    fetchMenus() {
      return this.$store.dispatch('link/fetchRsFooter');
    },
  },
};
</script>
<style lang="scss" scoped>
.rs-blog-list {
  --rs-license-bordder-color: #dadada;
  --rs-license-z-index-0: 0;
  --rs-license-z-index-1: 1;
  --rs-license-z-index-2: 2;
  --rs-license-z-index-3: 3;
  --rs-license-z-index-4: 4;
  --rs-license-black-color: #181818;
  --rs-solution-pad-horizontal: #{size(20)};
  color: var(--rs-black-color);
  font-family: var(--rz-hero-font);
  * {
    box-sizing: border-box;
    .solution-banner.resource {
      box-sizing: initial;
    }
  }
  .pad-top-10 {
    padding-top: size(10);
  }
  .pad-top-6 {
    padding-top: size(6);
  }
  .pad-top-30 {
    padding-top: size(30);
  }
  .inline-block {
    display: inline-block;
  }
  .common-wrapper {
    max-width: size(1080);
    margin: 0 auto;
  }
  .hero-section {
    background: var(--rz-bg-color);
    padding: size(60) size(20);
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20);
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(120) size(0);
    }
    h1 {
      font-size: size(30);
      line-height: size(42);
      color: var(--rs-white-color);
      max-width: size(1080);
      margin: 0 auto;
      font-weight: 800;
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(60);
        line-height: size(74);
      }
    }
  }
  .container {
    margin: auto;
    padding: 0 var(--rs-solution-pad-horizontal);
    @media screen and (min-width: $breakpoint-lg) {
      max-width: size(1080);
      padding: 0;
    }
    margin-bottom: size(50);
    margin-top: size(50);
    @media screen and (min-width: $breakpoint-md) {
      margin-bottom: size(80);
      margin-top: size(80);
    }
    @media screen and (min-width: $breakpoint-lg) {
      margin-bottom: size(100);
      margin-top: size(100);
    }
  }
  .max-width-1440 {
    max-width: size(1440);
    margin: 0 auto;
    position: relative;
  }
  .archive-btn {
    display: flex;
    justify-content: center;
    margin: size(10) 0 0;
  }
}
</style>
<style lang="scss">
.rs-blog-list {
  --rs-blog-border-radius: #{size(6)};
  .blog-list-two-col {
    .flex-child.two .image-item {
      img {
        height: auto;
        max-height: size(600);
        border-radius: var(--rs-blog-border-radius);
      }
    }
  }
  .rz-pre-footer {
    padding: size(40) size(20) 0;
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(60) size(0) 0;
    }
  }
}
</style>
