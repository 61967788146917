<template>
  <div class="rz-home-old" v-if="pageState.status.isLoaded && page">
    <div v-if="page.sections && page.sections.length">
      <section v-for="(section, index) in page.sections" :key="index">
        <section class="rz-hero-container" v-if="section.static_section_id == 'banner'">
          <div class="hero-section common-wrapper">
            <div class="content-wrap">
              <h1>{{section.detail.name}}</h1>
              <p>{{section.detail.desc}}</p>
              <a class="btn-style" :href="section.detail.links[0].url">{{section.detail.links[0].name}}
                <svg><use v-bind="{'xlink:href':'#home-arrow'}"></use></svg></a>
            </div>
            <div class="img-wrap">
              <div class="wheel-fig"></div>
              <div class="rectangle-fig"></div>
              <rz-image-item
                v-if="section.detail.img" :image="section.detail.img" />
            </div>
          </div>
        </section>
        <section class="rz-heading-content" v-if="section.static_section_id == 'sports_tech_copy'">
          <div class="common-wrapper">
            <rz-markdown :text="section.detail.desc"></rz-markdown>
          </div>
        </section>
        <section id="products" class="rz-products-container" v-if="section.static_section_id == 'rz_products'">
          <div class="products common-wrapper">
            <div class="products-inner-wrapper">
              <div v-for="item in section.detail.items" :key="item.key">
                <rz-image-item
                  v-if="item.img" :image="item.img" />
                <h4>{{item.name}}</h4>
                <p>{{item.desc}}</p>
                <a :href="item.links[0].url">{{item.links[0].name}}
                  <svg><use v-bind="{'xlink:href':'#home-arrow-blue-1'}"></use></svg></a>
              </div>
            </div>
          </div>
        </section>
        <section class="rz-push-config-container" v-if="section.static_section_id == 'home_dev_copy'">
          <div class="common-wrapper push-config">
            <div>
              <h3>{{section.detail.name}}</h3>
              <p>{{section.detail.desc}}</p>
              <rz-image-item
                v-if="section.detail.img" :image="section.detail.img" />
            </div>
          </div>
        </section>
        <section class="rz-heading-content" v-if="section.static_section_id == 'home_consulting'">
          <div class="common-wrapper">
            <rz-markdown :text="section.detail.desc"></rz-markdown>
          </div>
        </section>
        <section class="rz-consulting-list" v-if="section.static_section_id == 'consulting_list'">
          <div class="common-wrapper">
            <h5>Our Solutions</h5>
            <h4>{{section.detail.items[0].name}}</h4>
            <div class="list-wrapper container-1">
              <div class="content-wrapper">
                <p>{{section.detail.items[0].desc}}</p>
                <a class="btn-style" :href="section.detail.items[0].links[0].url">
                  {{section.detail.items[0].links[0].name}}
                  <svg><use v-bind="{'xlink:href':'#home-arrow'}"></use></svg></a>
              </div>
              <rz-image-item
                v-if="section.detail.items[0].img" :image="section.detail.items[0].img" />
            </div>
            <div class="list-wrapper container-2">
              <div class="content-wrapper">
                <h4>{{section.detail.items[1].name}}</h4>
                <p>{{section.detail.items[1].desc}}</p>
                <a class="btn-style" :href="section.detail.items[1].links[0].url">
                  {{section.detail.items[1].links[0].name}}
                  <svg><use v-bind="{'xlink:href':'#home-arrow'}"></use></svg></a>
              </div>
              <rz-image-item
                v-if="section.detail.items[1].img" :image="section.detail.items[1].img" />
            </div>
          </div>
        </section>
        <section class="rz-newsroom-list" v-if="section.static_section_id == 'newsroom'">
          <div class="common-wrapper">
            <h4>Newsroom</h4>
            <div class="news-container-wrapper">
              <div :class="'news-container-'+index"
                v-for="(item , index) in section.detail.items" :key="item.key">
                <rz-image-item
                v-if="item.img" :image="item.img" />
                <h5>{{item.name}}</h5>
                <p>{{item.desc}}</p>
                <a :href="item.links[0].url">
                  {{item.links[0].name}}
                  <svg><use v-bind="{'xlink:href':'#home-arrow-blue-1'}"></use></svg></a>
              </div>
            </div>
            <a class="more-news">
              See More News
              <svg><use v-bind="{'xlink:href':'#home-arrow-blue-1'}"></use></svg></a>
          </div>
        </section>
        <section class="rz-join-us" v-if="section.static_section_id == 'openings'">
          <join-us-section :section="section"/>
        </section>
        <section class="rz-pre-footer" v-if="section.static_section_id == 'pre_footer'">
          <div class="common-wrapper">
            <pre-footer :section="section"/>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import JoinUsSection from '@/components/JoinUsSection.vue';
import PreFooter from '@/components/PreFooter.vue';

export default {
  name: 'rzHome',
  components: {
    JoinUsSection,
    PreFooter,
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
      rzFooterLinks: (state) => state.link.rzFooterLinks,
    }),
  },
  serverPrefetch() {
    return this.fetchData();
  },
  data() {
    return {
      articles: null,
      limit: 10,
      pagesArray: [],
      currentPage: 1,
      totalpages: null,
      pageCount: 0,
      articleLoaded: false,
    };
  },
  mounted() {
    console.log('this.dataLoaded', this.dataLoaded);
    if (this.dataLoaded) {
      const sameRoute = this.page.route_value === this.$route.params.page;
      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
        return;
      }
    }

    if (!this.dataLoaded) {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      return this.fetchMenus().then(() => {
        const route = '/roanuz/pages/';
        const routeValue = `rz-${this.$route.meta.routeValue}`;
        return this.$store.dispatch('page/fetchActivePage', { route, routeValue });
      });
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.fetchData();
    },
    fetchMenus() {
      return this.$store.dispatch('link/fetchRzFooter');
    },
  },
};
</script>
<style lang="scss">
.rz-home-old {
  .common-wrapper {
    max-width: size(1080);
    margin: 0 auto;
  }
  .rz-hero-container {
    .image-item {
      border-radius: size(20);
      img {
        border-radius: size(20);
        display: inline-block;
        position: absolute;
        z-index: 2;
        top: size(20);
        left: size(45);
        object-fit: cover;
        @media screen and (min-width: $breakpoint-md) {
          width: size(540);
          left: size(60);
        }
      }
    }
  }
  .rz-heading-content {
    .markdown {
      display: block;
      font-weight: 800;
      font-size: size(32);
      line-height: size(42);
      color: var(--rz-copy-color);
      padding-top: size(50);
      margin: 0 auto;
      padding: size(50) size(20) 0;
      @media screen and (min-width: $breakpoint-md) {
        padding: size(80) size(20) 0;
      }
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(60);
        line-height: size(74);
        padding-top: size(120);
      }
      span {
        display: block;
        max-width: 100%;
      }
      em {
        color: var(--rz-btn-color);
      }
    }
  }
  .rz-push-config-container {
    .image-item {
      border-radius: size(20);
      img {
        width: 100%;
        border-radius: size(20);
        height: size(250);
        object-fit: cover;
        @media screen and (min-width: $breakpoint-md) {
          height: size(400);
        }
        @media screen and (min-width: $breakpoint-lg) {
          height: size(600);
        }
      }
    }
  }
  .rz-consulting-list {
    .list-wrapper {
      &.container-1 {
        .image-item {
          padding-left: 0;
          padding-top: size(30);
          border-radius: size(10);
          text-align: left;
          padding-bottom: size(20);
          @media screen and (min-width: $breakpoint-md) {
            text-align: right;
            padding-top: 0;
            padding-left: size(20);
          }
          @media screen and (min-width: $breakpoint-lg) {
            padding-left: 0;
          }
          img {
            border-radius: size(10);
            height: size(200);
            @media screen and (min-width: $breakpoint-lg) {
              height: size(380);
            }
          }
        }
      }
      &.container-2 {
        flex-direction: row-reverse;
        padding-top: size(30);
        @media screen and (min-width: $breakpoint-md) {
          padding-top: size(50);
        }
        @media screen and (min-width: $breakpoint-lg) {
          align-items: flex-end;
          padding-top: size(120);
        }
        .image-item {
          padding-right: 0;
          padding-top: size(30);
          border-radius: size(10);
          text-align: left;
          @media screen and (min-width: $breakpoint-md) {
            padding-top: 0;
            padding-right: size(20);
          }
          @media screen and (min-width: $breakpoint-lg) {
            padding-right: 0;
          }
          img {
            border-radius: size(10);
            height: size(300);
            object-fit: cover;
            @media screen and (min-width: $breakpoint-md) {
              height: size(480);
            }
            @media screen and (min-width: $breakpoint-lg) {
              height: size(680);
            }
          }
        }
      }
    }
  }
  .rz-newsroom-list {
    .news-container-wrapper {
      position: relative;
    }
    .news-container-0 {
      width: 100%;
      @media screen and (min-width: $breakpoint-md) {
        width: 40%;
      }
      @media screen and (min-width: $breakpoint-lg) {
        width: 45%;
      }
      h5 {
        color: #27AE60;
      }
      .image-item {
        height: size(300);
        border-radius: size(10);
        @media screen and (min-width: $breakpoint-md) {
          height: size(236);
          text-align: left;
        }
        @media screen and (min-width: $breakpoint-lg) {
          height: size(400);
        }
        img {
          display: inline-block;
          border-radius: size(10);
          width: 100%;
          object-fit: cover;
          @media screen and (min-width: $breakpoint-md) {
            width: size(290);
          }
          @media screen and (min-width: $breakpoint-lg) {
            width: size(600);
          }
        }
      }
    }
    .news-container-1 {
      width: 100%;
      padding-top: size(40);
      @media screen and (min-width: $breakpoint-md) {
        position: absolute;
        top: size(140);
        right: size(20);
        width: 45%;
        padding-top: 0;
      }
      @media screen and (min-width: $breakpoint-lg) {
        top: size(200);
        right: 0;
      }
      h5 {
        color: #FF8282;
      }
      .image-item {
        height: size(340);
        border-radius: size(10);
        @media screen and (min-width: $breakpoint-md) {
          height: size(300);
          text-align: left;
        }
        @media screen and (min-width: $breakpoint-lg) {
          height: size(560);
        }
        img {
          display: inline-block;
          border-radius: size(10);
          width: 100%;
          object-fit: cover;
          @media screen and (min-width: $breakpoint-md) {
            width: size(200);
          }
          @media screen and (min-width: $breakpoint-lg) {
            width: size(400);
          }
        }
      }
    }
    .news-container-2 {
      padding-top: size(40);
      width: 100%;
      @media screen and (min-width: $breakpoint-md) {
        width: 40%;
      }
      @media screen and (min-width: $breakpoint-lg) {
        width: 45%;
      }
      h5 {
        color: #BB6BD9;
      }
      .image-item {
        height: size(300);
        border-radius: size(10);
        @media screen and (min-width: $breakpoint-md) {
          height: size(200);
          text-align: left;
        }
        @media screen and (min-width: $breakpoint-lg) {
          height: size(378);
        }
        img {
          display: inline-block;
          border-radius: size(10);
          width: 100%;
          object-fit: cover;
          @media screen and (min-width: $breakpoint-md) {
            width: size(290);
          }
          @media screen and (min-width: $breakpoint-lg) {
            width: size(600);
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.rz-home-old {
  font-family: var(--rz-hero-font);
  .rz-hero-container {
    background: var(--rz-bg-color);
    padding: size(40) size(20);
    @media screen and (min-width: $breakpoint-md) {
      padding: size(40) size(30);
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(60) size(0) size(0);
    }
  }
  .btn-style {
    text-decoration: none;
    padding: size(16) size(20);
    background: var(--rz-btn-color);
    color: var(--rs-white-color);
    display: inline-block;
    border-radius: size(5);
    font-weight: bold;
    font-size: size(15);
    line-height: size(21);
    cursor: pointer;
    margin-left: size(2);
    svg {
      display: inline-block;
      width: size(4);
      height: size(8);
      fill: transparent;
      transition: margin 0.3s linear;
    }
    &:hover {
      svg {
        margin-left: size(8);
      }
    }
  }
  .hero-section {
    .content-wrap {
      color: var(--rs-white-color);
      max-width: 100%;
      display: inline-block;
      vertical-align: top;
      @media screen and (min-width: $breakpoint-md) {
        max-width: size(300);
      }
      @media screen and (min-width: $breakpoint-lg) {
        max-width: size(450);
      }
      h1 {
        max-width: size(460);
        font-weight: 800;
        font-size: size(32);
        line-height: size(42);
        padding-bottom: size(20);
        padding-top: size(60);
        @media screen and (min-width: $breakpoint-lg) {
          font-size: size(60);
          line-height: size(74);
        }
      }
      p {
        font-weight: normal;
        font-size: size(22);
        line-height: size(32);
        padding-bottom: size(20);
      }
      a {
        width: size(110);
      }
    }
    .img-wrap {
      display: inline-block;
      width: 100%;
      height: size(300);
      position: relative;
      @media screen and (min-width: $breakpoint-md) {
        width: 40%;
        height: size(400);
      }
      @media screen and (min-width: $breakpoint-lg) {
        width: 52%;
        height: size(700);
      }
      .wheel-fig {
        display: inline-block;
        width: size(200);
        height: size(180);
        background: url('../../src/assets/rz-website/rz-bg-1.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        right: size(0);
        transition: transform 0.3s linear;
        -webkit-animation:spin 10s linear infinite;
        -moz-animation:spin 10s linear infinite;
        animation:spin 10s linear infinite;
        @media screen and (min-width: $breakpoint-md) {
          top: size(70);
          right: size(-100);
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: size(306);
          height: size(280);
          top: size(70);
          right: size(-120);
        }
      }
      @-moz-keyframes spin {
        100% {
          -moz-transform: rotate(360deg);
        }
      }
      @-webkit-keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
        }
      }
      @keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
          transform:rotate(360deg);
        }
      }
      .rectangle-fig {
        display: inline-block;
        width: size(180);
        height: size(180);
        background: url('../../src/assets/rz-website/rz-bg-2.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        bottom: size(0);
        transition: transform 0.4s linear;
        -webkit-animation:spin 8s linear infinite;
        -moz-animation:spin 8s linear infinite;
        animation:spin 8s linear infinite;
        @media screen and (min-width: $breakpoint-md) {
          left: size(0);
          bottom: size(20);
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: size(306);
          height: size(280);
          left: size(0);
          bottom: size(100);
        }
      }
      @-moz-keyframes spin {
        100% {
          -moz-transform: rotate(360deg);
        }
      }
      @-webkit-keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
        }
      }
      @keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
          transform:rotate(360deg);
        }
      }
    }
  }
  .rz-products-container {
    padding-top: size(50);
    @media screen and (min-width: $breakpoint-md) {
      padding-top: size(80);
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding-top: size(100);
    }
    .products-inner-wrapper {
      text-align: center;
      color: var(--rz-copy-color);
      @media screen and (min-width: $breakpoint-lg) {
        text-align: left;
      }
      div {
        margin: size(15);
        transition: transform 0.5s linear;
        -webkit-animation:slide .5s linear;
        -moz-animation:slide .5s linear;
        animation:slide .5s linear;
        text-align: left;
        @-moz-keyframes slide {
          from {
            -moz-transform: translateX(#{size(-100)});
          }
          to {
            -moz-transform: translateX(0);
          }
        }
        @-webkit-keyframes slide {
          from {
            -webkit-transform: translateX(#{size(-100)});
          }
          to {
            -webkit-transform: translateX(0);
          }
        }
        @keyframes slide {
          from {
            -webkit-transform: translateX(#{size(-100)});
            transform: translateX(#{size(-100)});
          }
          to {
            -webkit-transform: translateX(0);
            transform:translateX(0);
          }
        }
        @media screen and (min-width: $breakpoint-md) {
          display: inline-block;
          vertical-align: top;
          width: calc((100% / 2) - #{size(40)});
          margin: size(10) size(20);
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: calc((100% / 3) - #{size(40)});
          vertical-align: top;
          margin: 0;
          &:nth-child(2) {
            margin-left: size(60);
            margin-right: size(60);
          }
        }
        .image-item {
          width: size(225);
          height: size(225);
          margin: 0;
          @media screen and (min-width: $breakpoint-lg) {
            text-align: left;
          }
        }
        h4 {
          font-size: size(24);
          line-height: size(34);
          padding-bottom: size(20);
          font-weight: 800;
          text-align: left;
        }
        p {
          font-size: size(18);
          line-height: size(28);
          padding-bottom: size(20);
          text-align: left;
        }
        a {
          text-decoration: none;
          font-weight: bold;
          font-size: size(15);
          line-height: size(21);
          color: #006AFF;
          display: inline-block;
          margin-left: size(2);
          text-align: left;
          svg {
            display: inline-block;
            width: size(8);
            height: size(8);
            transition: margin 0.3s linear;
            fill: none;
            stroke: var(--rz-btn-color)
          }
          &:hover {
            svg {
              margin-left: size(8);
            }
          }
        }
      }
    }
  }
  .rz-push-config-container {
    padding: size(50) size(20) size(0);
    text-align: center;
    color: var(--rz-copy-color);
    @media screen and (min-width: $breakpoint-lg) {
      padding: 0;
      padding-top: size(120)
    }
    h3 {
      font-weight: 800;
      font-size: size(32);
      line-height: size(42);
      text-align: center;
      padding-bottom: size(20);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(60);
        line-height: size(74);
      }
    }
    p {
      font-size: size(18);
      line-height: size(28);
      text-align: center;
      color: var(--rz-copy-color);
      padding-bottom: size(25);
      margin: 0 auto;
      max-width: size(900);
      margin: 0 auto;
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(30);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding-bottom: size(40);
      }
    }
  }
  .rz-consulting-list {
    padding: size(50) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(120) size(0) 0;
    }
    h5 {
      font-weight: bold;
      font-size: size(18);
      line-height: size(25);
      color: var(--rz-copy-color);
      text-align: left;
      padding-bottom: size(20);
    }
    h4 {
      font-style: normal;
      font-weight: 800;
      font-size: size(32);
      line-height: size(42);
      color: var(--rz-copy-color);
      padding-bottom: size(20);
      max-width: size(600);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(60);
        line-height: size(74);
      }
    }
    .list-wrapper {
      color: var(--rz-copy-color);
      @media screen and (min-width: $breakpoint-md) {
        display: flex;
        justify-content: space-between;
      }
      p {
        font-weight: normal;
        font-size: size(18);
        line-height: size(28);
        padding-bottom: size(20);
      }
      a {
        display: inline-block;
        width: size(215);
      }
    }
  }
  .rz-newsroom-list {
    padding-top: size(50);
    @media screen and (min-width: $breakpoint-md) {
      padding-top: size(80);
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding-top: size(120);
    }
    h4 {
      font-weight: 800;
      font-size: size(32);
      line-height: size(42);
      color: var(--rz-copy-color);
      padding: 0 size(20);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(60);
        line-height: size(74);
        padding: 0;
      }
    }
    a {
      text-decoration: none;
      font-weight: bold;
      font-size: size(15);
      line-height: size(21);
      color: #006AFF;
      display: inline-block;
      margin-left: size(2);
      cursor: pointer;
      svg {
        display: inline-block;
        width: size(8);
        height: size(8);
        transition: margin 0.3s linear;
        fill: none;
        stroke: var(--rz-btn-color)
      }
      &:hover {
        svg {
          margin-left: size(8);
        }
      }
      &.more-news {
        color: var(--rz-copy-color);
        padding: 0 size(20);
        margin-top: size(20);
        svg {
          stroke: var(--rz-copy-color);
        }
        @media screen and (min-width: $breakpoint-lg) {
          padding: 0;
          margin-top: size(40);
        }
      }
    }
    .news-container-wrapper {
      padding: size(30) size(20) 0;
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(40) 0 0;
      }
      h5 {
        font-weight: bold;
        font-size: size(15);
        line-height: size(21);
        padding-bottom: size(10);
        padding-top: size(15);
        @media screen and (min-width: $breakpoint-lg) {
          padding-top: size(20);
        }
      }
      p {
        font-weight: bold;
        font-size: size(24);
        line-height: size(30);
        color: var(--rz-copy-color);
        padding-bottom: size(20);
        max-width: size(600);
        @media screen and (min-width: $breakpoint-lg) {
          font-size: size(30);
          line-height: size(42);
        }
      }
      .news-right-container {
        @media screen and (min-width: $breakpoint-md) {
          padding-left: size(40);
        }
        @media screen and (min-width: $breakpoint-lg) {
          padding-left: size(80);
        }
      }
    }
  }
}

</style>
