<template>
  <div v-if="footerLinks.loaded">
    <div class="footer common-wrapper">
      <div class="footer-container">
        <section class="all-links">
          <div class="inner-block">
            <div class="company">
              <a href="/" class="image-wrap">
                <img src="@/assets/rz-website/rz-logo-1.svg" alt="Roanuz"/>
              </a>
            </div>
            <div class="rs-flex-box">
              <div class="links" v-for="(linkslist,index) in footerLinks.links" :key="index">
                <h3>{{linkslist.name}}</h3>
                <div class="links-section-wrapper" :class="linkslist.name">
                  <div
                    class="links-section"
                    >
                    <ul>
                      <li
                        v-for="link in linkslist.linkslist.links"
                        :key="link.key"
                      >
                        <TextButton :class="link.name" :link="link" :buttonType="'plain'"/>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="tools-section" v-if="footerLinks && footerLinks.tools &&
                  (index === (footerLinks.links.length - 1))">
                  <h3>{{footerLinks.tools.name}}</h3>
                  <div class="links-section-wrapper">
                    <div
                      class="links-section"
                      >
                      <ul>
                        <li
                          v-for="link in footerLinks.tools.linkslist.links"
                          :key="link.key"
                        >
                          <TextButton :link="link" :buttonType="'plain'"/>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="links button-wrapper">
                <div class="console-btn-wrapper">
                  <ConsoleButton />
                </div>
                <div class="support-btn-wrapper">
                  <a :href="supportNumbers.usTollfree.link">{{supportNumbers.usTollfree.label}}</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section class="toc-links">
        <div class="inner-block">
          <div class="company">
            <p class="rights">© Copyright Roanuz Softwares Private Limited. All rights reserved</p>
            <p class="privacy"><a href="https://www.cricketapi.com/legal/Privacy-Policy/">Terms & Privacy policy</a></p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import ConsoleButton from '@/components/Utils/ConsoleButton.vue';
import TextButton from '@/components/Shared/TextButton.vue';
import { supportNumbers } from '@/store/modules/page';

export default {
  components: {
    TextButton,
    ConsoleButton,
  },
  props: {
    footerLinks: null,
  },
  data() {
    return {
      supportNumbers,
    };
  },
};
</script>
<style lang="scss">
.home-footer-block {
  &.rz-footer {
    .footer {
      &.common-wrapper {
        max-width: size(1080);
        margin: 0 auto;
      }
      .all-links {
        .inner-block {
          .company {
            .image-wrap {
              img {
                display: inline-block;
                width: size(50);
                height: sie(52);
                @media screen and (min-width: $breakpoint-md) {
                  width: size(60);
                  height: sie(62);
                }
                @media screen and (min-width: $breakpoint-lg) {
                  width: size(72);
                  height: sie(80);
                }
              }
            }
          }
          .links {
            h3 {
              font-size: size(12);
              line-height: size(17);
              color: #777777;
              padding-bottom: size(10);
              margin-bottom: 0;
            }
            &.button-wrapper {
              display: block;
              width: 100%;
              @media screen and (min-width: $breakpoint-md) {
                display: flex;
                flex-direction: column;
                width: auto;
              }
            }
            .support-btn-wrapper {
              text-decoration: none;
              color: var(--rs-white-color);
              display: inline-block;
              padding: size(10);
              border-radius: size(5);
              background: var(--regular-text-inv);
              border: size(1) solid var(--rz-copy-color);
              font-weight: bold;
              font-size: size(15);
              line-height: size(21);
              cursor: pointer;
              text-transform: capitalize;
              margin-bottom: size(14);
              @media screen and (min-width: $breakpoint-md) {
                padding: size(10) size(7);
              }
              svg {
                display: inline-block;
                width: size(4);
                height: size(8);
                fill: transparent;
              }
              a {
                &:hover {
                  color: var(--rz-copy-color);
                }
              }
            }
            .links-section-wrapper {
              .text-button {
                .link-text {
                  font-size: size(15);
                  line-height: size(21);
                  color: var(--rz-copy-color);
                  font-weight: normal;
                }
              }
              &.Follow {
                .text-button {
                  .link-text {
                    padding-left: size(30);
                    position: relative;
                  }
                }
              }
            }
          }
          .text-button {
            .link {
              padding-bottom: size(14);
              padding-top: 0;
            }
            .link-text {
              &::before {
                display: inline-block;
                width: size(21);
                height: size(21);
                vertical-align: middle;
                position: absolute;
                left: 0
              }
            }
            &.Instagram {
              .link-text {
                &::before {
                  content: '';
                  background: url('../assets/rz-website/instagram.svg') no-repeat;
                }
              }
            }
            &.Twitter {
              .link-text {
                &::before {
                  content: '';
                  background: url('../assets/rz-website/twitter.svg') no-repeat;
                }
              }
            }
            &.Facebook {
              .link-text {
                &::before {
                  content: '';
                  background: url('../assets/rz-website/facebook.svg') no-repeat;
                }
              }
            }
            &.LinkedIn {
              .link-text {
                &::before {
                  content: '';
                  background: url('../assets/rz-website/linkedin.svg') no-repeat;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
