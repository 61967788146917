<template>
  <div class="join-us-wrapper" id="join-us">
    <div class="common-wrapper">
      <h5>Join Us</h5>
      <div class="content-wrapper">
        <div class="left-container">
          <h4>{{section.detail.items[0].name}}</h4>
          <p>{{section.detail.items[0].desc}}</p>
          <div class="inner-wrapper">
            <!-- <div id="job-openings-wrapper">
              <template  v-for="item in section.detail.items[0].links">
                <template v-if="item.element_class !== 'explore-button'">
                  <a :href="item.url" class="job-openings-container"  :key="item.key">
                    <span class="designation">{{item.name}}</span>
                    <span class="description">{{item.desc}}</span>
                  </a>
                </template>
              </template>
            </div> -->
          </div>
          <a class="explore-btn"
          :href="section.detail.items[0].links[1].url">{{section.detail.items[0].links[1].name}}
          <svg><use v-bind="{'xlink:href':'#home-arrow'}"></use></svg></a>
        </div>
        <div class="right-container new-version">
           <img src="https://assets.sports.dev.roanuz.com/uploads-v5/roanuz/images/rnz1.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    section: Object,
  },
  mounted() {
    this.changeEffect();
  },
  methods: {
    changeEffect() {
      window.addEventListener('scroll', () => {
        const el = document.getElementById('join-us');
        const height = el.offsetHeight;
        const topVal = el.offsetTop - 450;
        const scrollVal = window.scrollY;
        const totalHeight = topVal + height;
        if ((topVal < scrollVal) && (scrollVal <= totalHeight)) {
          el.className = 'join-us-wrapper add-bg';
          this.showSlides();
        } else {
          el.className = 'join-us-wrapper';
        }
      });
    },
    showSlides() {
      const slides = document.getElementsByClassName('job-openings-container');
      const container = document.getElementById('job-openings-wrapper');
      let i = 0;
      window.setInterval(() => {
        if (i < slides.length) {
          const width = slides[i].clientWidth;
          const slideVal = i * (width);
          container.style.transform = `translateX(-${slideVal}px)`;
          i += 1;
        } else if (i >= slides.length) {
          container.style.transform = 'translateX(0)';
          i = 0;
        } else if (slides.length === 1) {
          container.style.transform = 'translateX(0)';
        }
      }, 3500);
    },
  },
};
</script>
<style lang="scss">
.join-us-wrapper {
  .content-wrapper {
    .right-container {
      .image-item {
        text-align: left;
        border-radius: size(10);
        object-fit: cover;
        img {
          border-radius: size(10);
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.join-us-wrapper {
  color: var(--rz-copy-color);
  padding: size(50) size(20);
  margin-top: size(20);
  background: var(--regular-text-inv);
  transition: background-color 1s ease, color 1s ease;
  &.add-bg {
    color: var(--regular-text-inv);
    background: var(--rz-bg-color);
    .content-wrapper {
      .left-container {
        .job-openings-container {
          color: var(--regular-text-inv);
          .description {
            color: var(--regular-text-inv);
          }
          &:hover {
            .description {
              color: var(--regular-text-inv);
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: $breakpoint-lg) {
    margin-top: size(80);
    padding: size(80) size(20);
  }
  @media screen and (min-width: $breakpoint-lg) {
    margin-top: size(120);
    padding: size(120) 0;
  }
  h5 {
    font-weight: bold;
    font-size: size(18);
    line-height: size(25);
    padding-bottom: size(20);
  }
  .content-wrapper {
    @media screen and (min-width: $breakpoint-md) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .left-container {
      max-width: size(500);
      @media screen and (min-width: $breakpoint-md) {
        padding-right: size(30);
      }
      h4 {
        font-weight: 800;
        font-size: size(30);
        line-height: size(42);
        padding-bottom: size(20);
        @media screen and (min-width: $breakpoint-lg) {
          font-size: size(60);
          line-height: size(74);
        }
      }
      p {
        font-size: size(18);
        line-height: size(28);
        padding-bottom: size(20);
      }
      .inner-wrapper {
        overflow: hidden;
      }
      #job-openings-wrapper {
        position: relative;
        max-width: size(500);
        margin: auto;
        display: flex;
        transition: transform 0.4s ease;
      }
      .job-openings-container {
        display: inline-block;
        border-left: size(2) solid #BB6BD9;
        margin-bottom: size(60);
        flex: 0 0 100%;
        .designation {
          padding-left: size(20);
          display: inline-block;
          font-size: size(18);
          line-height: size(25);
          color: #BB6BD9;
          padding-bottom: size(4);
        }
        .description {
          padding-left: size(20);
          display: inline-block;
          font-size: size(15);
          line-height: size(21);
          color: var(--rz-copy-color);
        }
      }
      .explore-btn {
        background: linear-gradient(96.49deg, #006AFF 5.96%, #6739C9 61.6%, #FBF144 114.96%);
        animation: gradient 8s ease infinite;
        border-radius: size(5);
        padding: size(16) size(20);
        transition: 0.6s linear;
        background-size: 400% 400%;
        display: inline-block;
        cursor: pointer;
        text-decoration: none;
        font-weight: bold;
        font-size: size(15);
        line-height: size(21);
        color: var(--regular-text-inv);
        svg {
          display: inline-block;
          width: size(8);
          height: size(8);
          fill: transparent;
          margin-left: size(5);
          transition: transform 0.3s linear;
        }
        &:hover {
          svg {
            transform: translate(#{size(4)});
          }
        }
        @keyframes gradient {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }

      }
    }
    .right-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: size(20);
      @media screen and (min-width: $breakpoint-md) {
        margin-top: 0;
      }
      &.new-version {
        display: inline-block;
        vertical-align: top;
        max-width: size(500);
        img {
          width: size(300);
          @media screen and (min-width: $breakpoint-md) {
            width: size(350);
          }
          @media screen and (min-width: $breakpoint-lg) {
            width: size(500);
          }
        }
      }
      .image-item {
        text-align: left;
        border-radius: size(10);
        object-fit: cover;
        img {
          border-radius: size(10);
        }
      }
      .img-wrapper-1 {
        padding-right: size(25);
        .image-item {
          height: size(300);
          @media screen and (min-width: $breakpoint-lg) {
            height: size(375);
            width: size(250);
          }
        }
      }
      .img-wrapper-2 {
        .image-item {
          &:first-child {
            height: size(180);
            padding-bottom: size(25);
            @media screen and (min-width: $breakpoint-lg) {
              height: size(208);
            }
          }
          &:last-child {
            height: size(200);
          }
        }
      }
    }
  }
}
</style>
