<template>
  <a
    :href="consoleLink"
    title="Console" class="btn btn--outline console-btn btn-style" rel="noopener">
    <!-- multihost user issue -->
    <!-- <span v-if="bootData.user && bootData.user.user">Hey {{bootData.user.name}}</span>
    <span v-else-if="activeUser && activeUser.loggedIn">Console</span>
    <span v-else>Sign in</span> -->
    Console
    <svg><use v-bind="{'xlink:href':'#home-arrow'}"></use></svg>
  </a>
</template>
<style lang="scss" scoped>
</style>
<script>
import { mapState } from 'vuex';
import Config from '@/config';

export default {
  name: 'ErrorState',
  computed: {
    ...mapState({
      bootData: (state) => state.boot.instance,
      activeUser: (state) => state.user.activeUser,
    }),
  },
  props: {
    isPaygo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      consoleLink: Config.consoleHost,
    };
  },
};
</script>
<style lang="scss" scoped>
.console-btn {
  background: var(--rz-btn-color);
  &.btn-style {
    text-decoration: none;
    padding: size(10) size(20);
    background: var(--rz-btn-color);
    color: var(--rs-white-color);
    display: inline-block;
    border-radius: size(5);
    border: size(1) solid var(--rz-btn-color);
    font-weight: bold;
    font-size: size(15);
    line-height: size(21);
    cursor: pointer;
    width: size(100);
    text-transform: capitalize;
    margin-bottom: size(14);
    svg {
      display: inline-block;
      vertical-align: top;
      padding-top: size(6);
      width: size(8);
      height: size(10);
      fill: transparent;
      margin-left: size(5);
      transition: transform 0.3s linear;
    }
    &:hover {
      svg {
        transform: translate(#{size(4)});
      }
    }
  }
}
</style>
