var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-list-two-col"},[_c('div',{staticClass:"max-width-1080"},[_c('div',{staticClass:"flex-parent",class:_vm.type},[_c('div',{staticClass:"flex-child one"},[_c('div',{staticClass:"hash-tag"},[_vm._v(_vm._s(_vm.blog.category))]),_c('router-link',{attrs:{"to":{
              name: 'rsBlogDetailView',
              params: {blogid: _vm.blog.route_value.replace('rs-cricket-', '')}
            }}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.blog.card_title))])]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.blog.card_desc))]),_c('div',{staticClass:"blog-date"},[_vm._v(_vm._s(_vm.formatBlogPublicationDate(_vm.blog.publication_date)))]),_c('div',{staticClass:"author-container"},_vm._l((_vm.blog.author_keys),function(author,index){return _c('div',{key:index,staticClass:"author",class:'author-'+index},[_c('img',{staticClass:"author-pic",attrs:{"src":require(`@/assets/blog-${author.value}.png`),"alt":"author image"}}),_c('p',{staticClass:"author-name"},[_vm._v(_vm._s(author.key.replace('_',' ')))])])}),0)],1),_c('div',{staticClass:"flex-child two"},[_c('router-link',{attrs:{"to":{
              name: 'rsBlogDetailView',
              params: {blogid: _vm.blog.route_value.replace('rs-cricket-', '')}
            }}},[_c('rz-image-item',{attrs:{"image":_vm.blog.card_img}})],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }