<template>
  <div class="text-button" :class="buttonType">
    <router-link
      class="link"
      v-if="link.routeParams"
      :to="link.routeParams"
      :title="link.name"
      :exact-active-class="'active'">
      <span class="link-text">
        <slot>{{link.name}}</slot>
      </span>
    </router-link>
    <a class="link" :href="link.url" :title="link.name" :id="link.url == '#' ? 'my_custom_link': ''"
    :target="link.target" rel="noopener"
    v-else-if="link.url">
      <span class="link-text">
        <slot>{{link.name}}</slot>
      </span>
    </a>
    <span v-else class="link">
      <slot>{{link.name}}</slot>
    </span>
  </div>
</template>
<style lang="scss">
  .text-button {
    --rs-text-btn-gray-color: #EEEEEE;
    .link {
      font-size: size(18);
      line-height: size(24);
      font-weight: bold;
      color: var(--rz-link-color);
      padding: size(10) 0;
      display: block;
      font-family: var(--rz-para-font);
      &:hover {
        color: var(--rz-link-color);
        @include jump-up-animation;
      }
    }
    .link-text {
      display: inline-block;
      vertical-align: middle;
    }
    &.plain {
      .link {
        color: var(--rz-brand-type-color);
      }
    }
    @media screen and (min-width: $breakpoint-md) {
      .link {
        font-size: var(--rz-link-reg-font-size);
        line-height: var(--rz-link-reg-line-height);
        font-family: var(--rz-para-font);
      }
    }

    &.rs-button {
      display: inline-block;
      .link {
        font-size: var(--rs-title-level5-font-size);
        line-height: size(16);
        font-weight: bold;
        color: var(--rs-black-color);
        border: size(1) solid var(--rs-black-color);
        padding: size(14) size(24);
        display: inline-block;
        border-radius: size(5);
        margin-top: size(18);
        &:hover {
          @include jump-up-animation;
        }
        &:after {
          content: '';
          border: solid var(--rs-black-color);
          border-width: 0 size(2) size(2) 0;
          display: inline-block;
          padding: size(2.2);
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          margin-left: size(5);
          vertical-align: middle;
        }
      }
      &.yellow-cta {
        .link {
          background: var(--rs-cta-green-color);
          border: size(1) solid var(--rs-cta-green-color);
          margin-right: size(10);
          @media screen and (min-width: $breakpoint-md) {
            margin-right: size(20);
          }
        }
        &.white {
          .link {
            background: var(--rs-white-color);
            border: size(1) solid var(--rs-white-color);
          }
        }
      }
      &.blue-cta {
        .link {
          background: var(--rs-cta-blue-color);
          border: size(1) solid var(--rs-cta-blue-color);
          color: var(--rs-white-color);
          &:after {
            border: solid var(--rs-white-color);
            border-width: 0 size(2) size(2) 0;
          }
        }
      }
      &.white-cta {
        .link {
          border: size(1) solid var(--rs-white-color);
          color: var(--rs-white-color);
          &:after {
            border: solid var(--rs-white-color);
            border-width: 0 size(2) size(2) 0;
          }
        }
        &.no-space {
          .link {
            margin-left: 0;
          }
        }
        &.plain {
          .link {
            border: none;
            padding-left: 0;
            margin-left: 0;
          }
        }
      }
      &.plain-cta {
        .link {
          border: none;
          background: transparent;
          padding-left: 0;
          margin-top: 0;
        }
      }
      &.plain-green-cta {
        .link {
          color: var(--rs-cta-green-color);
          background: transparent;
          border: size(1) solid var(--rs-cta-green-color);;
          padding-left: size(24);
          &:after {
            border:size(1) solid var(--rs-cta-green-color);
            border-width: 0 size(2) size(2) 0;
          }
        }
      }
      &.plain-blue-cta {
        .link {
          color: var(--rs-cta-blue-color);
          background: transparent;
          border: none;
          padding-left: 0;
          margin-top: 0;
          &:after {
            border: solid var(--rs-cta-blue-color);
            border-width: 0 size(2) size(2) 0;
          }
        }
      }
      &.tick-btn {
        .link {
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
          border-radius: size(6);
          background: var(--rs-white-color);
          border: size(1) solid var(--rs-text-btn-gray-color);
          position: relative;
          padding: size(14) size(14) size(14) size(40);
          margin-right: size(14);
          &:before {
            content: '';
            display: inline-block;
            height: size(20);
            width: size(18);
            background-image: url(../../assets/rsCricket/tick.svg);
            background-repeat: no-repeat;
            position: absolute;
            left: size(15);
            top: size(12);
          }
          &:after {
            display: none;
          }
        }
      }
      &.lg {
        display: block;
        .link {
          padding-left: 0;
          padding-right: 0;
          display: block;
          width: 100%;
          text-align: center;
        }
      }
    }

    &.rz-button {
      .link {
        font-size: var(--rz-link-reg-font-size);
        line-height: var(--rz-link-reg-line-height);
        font-weight: bold;
        color: var(--rz-link-color);
        background: var(--rz-cta-light-color);
        border-radius: size(5);
        padding: size(10) size(20);
        border: size(1) solid var(--rz-cta-light-color);
        cursor: pointer;
        display: inline-block;
        &:after {
          content: '';
          border: solid var(--rz-link-color);
          border-width: 0 size(1) size(1) 0;
          display: inline-block;
          padding: size(4.3);
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          margin-left: size(5);
          vertical-align: middle;
        }
        &:hover {
          @include jump-up-animation;
        }
      }
      .arrow-right {
        padding-left: size(10);
        vertical-align: middle;
        img {
          transition: all .3s ease-in-out;
        }
        &.list-expanded {
          img {
            transform: rotate(90deg);
          }
        }
      }
      &.cta {
        .link {
          background: var(--rz-cta-color);
          color: var(--rz-highlight-color);
          border: size(1) solid var(--rz-cta-color);
          &:after {
            border: solid var(--rz-highlight-color);
            border-width: 0 size(1) size(1) 0;
          }
        }
      }
      &.outline {
        .link {
          background: transparent;
          color: var(--rz-cta-color);
          border: size(1) solid var(--rz-cta-color);
        }
      }
      &.text {
        .link {
          color: var(--rz-cta-color);
          background: transparent;
          border: none;
          padding-left: 0;
          &:after {
            border: solid var(--rz-cta-color);
            border-width: 0 size(2) size(2) 0;
            padding: size(2.5);
            margin-left: size(7);
          }
        }
      }
      &.small-hero {
        .link {
          font-size: size(22);
          line-height: size(22);
          color: var(--rz-hero-color);
          text-decoration: underline;
          background: transparent;
          border: none;
          padding-left: 0;
          &:after {
            display: none;
          }
        }
      }
    }
  }
</style>
<script>
export default {
  props: {
    buttonType: {
      type: String,
      default: null,
    },
    link: {
      type: Object,
    },
  },
};
</script>
