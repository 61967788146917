<template>
  <div>
    <div v-if="blog">
      <div class="content max-width-600 button-wrapper">
        <TextButton class="blog-btn" :buttonType="'rs-button'" :link="blogRoute" />
      </div>
      <div class="more-blogs">
        <h2 class="title">More Stories</h2>
        <div class="blog-list" v-if="blogListStatus">
          <template v-for="(blog, index) in blogList.blogs">
            <div class="list-wrap" :key="index" v-if="index !== blogList.length - 1">
              <div class="list" :class="'list'+index">
                <router-link
                  :to="{
                  name: 'rsBlogDetailView',
                  params: {blogid: blog.route_value.replace('rs-cricket-', '')}
                }"
                >
                  <div class="image-wrap" v-if="blog.card_img && blog.card_img.url">
                    <rz-image-item :image="blog.card_img" />
                  </div>
                  <div class="image-wrap" v-else>
                    <img class="image-item" src="@/assets/blog-placeholder.png" alt="gallery" />
                  </div>
                  <div class="title" v-if="blog.category">{{ blog.category}}</div>
                  <div class="desc" v-if="blog.card_title">{{ blog.card_title }}</div>
                  <div
                    class="date"
                    v-if="blog.publication_date"
                  >{{ formatBlogPublicationDate(blog.publication_date)}}</div>
                </router-link>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="contact-container" id="contact-container">
      <div class="wrapper">
        <p>{{section.detail.desc}}</p>
        <a class="btn-style" :href="section.detail.links[0].url">
        {{section.detail.links[0].name}}
        <svg><use v-bind="{'xlink:href':'#home-arrow'}"></use></svg></a>
      </div>
    </div>
 </div>
</template>
<script>
import TextButton from '@/components/Shared/TextButton.vue';

export default {
  props: {
    section: Object,
    blog: Boolean,
    blogListStatus: Boolean,
    blogList: Object,
  },
  components: {
    TextButton,
  },
  data() {
    return {
      blogRoute: {
        routeParams: { name: 'rsBlogList' },
        name: 'View All Blogs',
      },
    };
  },
  mounted() {
    this.changeEffect();
  },
  methods: {
    changeEffect() {
      window.addEventListener('scroll', () => {
        const el = document.getElementById('contact-container');
        const height = el.offsetHeight;
        const topVal = el.offsetTop - 440;
        const scrollVal = window.scrollY;
        const totalHeight = topVal + height;
        if ((topVal < scrollVal) && (scrollVal <= totalHeight)) {
          el.className = 'contact-container add-bg';
        } else {
          el.className = 'contact-container';
        }
      });
    },
  },
};
</script>
<style lang="scss">
.rz-contact-sales {
  .wrapper {
    max-width: size(910);
    margin: 0 auto;
  }
  .btn-style {
    text-decoration: none;
    padding: size(16) size(10);
    background: var(--rz-btn-color);
    color: var(--rs-white-color);
    display: inline-block;
    border-radius: size(5);
    font-weight: bold;
    font-size: size(15);
    line-height: size(21);
    cursor: pointer;
    svg {
      display: inline-block;
      vertical-align: middle;
      width: size(8);
      height: size(12);
      fill: transparent;
      margin-left: size(5);
      transition: transform 0.3s linear;
      margin-top: size(1);
    }
    &:hover {
      svg {
        transform: translate(#{size(4)});
      }
    }
  }
  .contact-container {
    padding: size(50) size(20);
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20);
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(120) size(0);
    }
    background: var(--rs-white-color);
    text-align: center;
    transition: background-color 1s ease, color 1s ease;
    &.add-bg {
      color: var(--regular-text-inv);
      background: var(--rz-bg-color);
      p {
        color: var(--regular-text-inv);
      }
    }
    p {
      font-weight: bold;
      font-size: size(24);
      line-height: size(33);
      text-align: center;
      color: var(--rz-copy-color);
      padding-bottom: size(40);
      max-width: 100%;
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(30);
        line-height: size(42);
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.rs-flex-box {
  display: flex;
  flex-wrap: wrap;
  &.space-between {
    justify-content: space-between;
  }
  &.align-center {
    align-items: center;
  }
  @media screen and (min-width: $breakpoint-md) {
    &.no-wrap {
      flex-wrap: initial;
    }
  }
}
.max-width-600 {
  max-width: size(600);
  margin: auto;
  text-align: center;
}
.button-wrapper {
  text-align: center;
  padding: size(26) 0 size(50);
  margin: size(40) size(20) 0;
  border-top: size(1) dashed var(--rs-gray-color);
  @media screen and (min-width: $breakpoint-md) {
    margin: size(40) auto 0;
    padding: size(26) 0 size(80);
  }
  @media screen and (min-width: $breakpoint-lg) {
    padding: size(26) 0 size(100);
  }
}
.more-blogs {
  max-width: size(1080);
  margin: auto;
  padding: 0 size(20) 0;
  @media screen and (min-width: $breakpoint-lg) {
    padding-bottom: size(70);
  }
  .title {
    color: var(--rs-black-color)
  }
  .list-wrap {
    border: none;
  }
  & > .title {
    padding-bottom: size(20);
    border-bottom: size(1) dashed var(--rs-gray-color);
    font-size: var(--rs-solution-para-font-size);
    line-height: var(--rs-solution-para-line-height);
  }
  .list-wrap {
    & {
      @include headerColorVariant(
        '.title',
        #b02907,
        #ff931e,
        #0751b9,
      );
    }
  }
}

.rs-container {
  max-width: size(1080);
  margin: 0 auto;
  padding: size(50) size(20);

  &.pad-130 {
    padding-top: size(130);
  }
  &.pad-100 {
    padding-top: size(100);
  }
  @media screen and (min-width: $breakpoint-lg) {
    padding: size(50) 0;
  }
}
</style>
