import { render, staticRenderFns } from "./SupportSection.vue?vue&type=template&id=705a37d0"
import script from "./SupportSection.vue?vue&type=script&lang=js"
export * from "./SupportSection.vue?vue&type=script&lang=js"
import style0 from "./SupportSection.vue?vue&type=style&index=0&id=705a37d0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports