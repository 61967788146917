<template>
    <div class="common-wrapper">
        <div class="image-wrapper" id="container"
          @mouseover="handleMove($event, 'container')"
          @mouseout="mouseOut('container')"
          @mouseup="mouseUp('container')"
          @mousedown="mouseDown('container')"
        >
            <rz-image-item
            v-if="section.detail.items[0].img" :image="section.detail.items[0].img" />
            <img id="container-1" src="@/assets/rz-website/Helloooo.svg" />
        </div>
        <div class="content-wrapper">
          <h1>{{section.name}}</h1>
          <div v-for="(item, index) in section.detail.items" :key="item.key">
            <template v-if="index > 0">
              <h4>{{item.name}}</h4>
              <div v-for="link in item.links" :key="link.key">
                <a :class="link.element_id ? link.element_id : ''"
                  :href="link.url" v-if="link.url">{{link.name}}</a>
                <p v-if="link.desc">{{link.desc}}</p>
              </div>
            </template>
          </div>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    section: Object,
  },
  methods: {
    handleMove(e, id) {
      if (window.matchMedia('(min-width: 1194px)')) {
        const el = document.getElementById(id);
        const height = el.clientHeight;
        const width = el.clientWidth;
        const xVal = e.layerX;
        const yVal = e.layerY;
        const yRotation = 30 * ((xVal - width / 2) / width);
        const xRotation = -30 * ((yVal - height / 2) / height);
        const string = ` scale(0.93) translate3d(0, 0, 0) rotateX(${xRotation}deg) rotateY(${yRotation}deg)`;
        el.style.transform = string;
        el.style.webkitTransform = string;
        el.style.transformStyle = 'flat';
        const container1 = document.getElementById('container-1');
        const yRotation1 = 45 * ((xVal - width / 2) / width);
        const xRotation1 = -45 * ((yVal - height / 2) / height);
        const string1 = ` translate3d(0, 0, 0) rotateX(${xRotation1}deg) rotateY(${yRotation1}deg)`;
        container1.style.transform = string1;
        container1.style.webkitTransform = string;
        container1.style.transformStyle = 'flat';
      }
    },
    mouseOut(id) {
      if (window.matchMedia('(min-width: 1194px)')) {
        const el = document.getElementById(id);
        el.style.transform = 'scale(1) rotateX(0) rotateY(0)';
        el.style.webkitTransform = 'scale(1) rotateX(0) rotateY(0)';
        el.style.transformStyle = 'flat';
        const container1 = document.getElementById('container-1');
        const string = 'translate3d(0, 0, 0)';
        container1.style.transform = string;
        container1.style.webkitTransform = string;
        container1.style.transformStyle = 'flat';
      }
    },
    mouseDown(id) {
      if (window.matchMedia('(min-width: 1194px)')) {
        const el = document.getElementById(id);
        el.style.transform = 'scale(0.9) rotateX(0) rotateY(0)';
        el.style.webkitTransform = 'scale(0.9) rotateX(0) rotateY(0)';
        el.style.transformStyle = 'flat';
        const container1 = document.getElementById('container-1');
        const string = 'translate3d(0, 0, 0)';
        container1.style.transform = string;
        container1.style.webkitTransform = string;
        container1.style.transformStyle = 'flat';
      }
    },
    mouseUp(id) {
      if (window.matchMedia('(min-width: 1194px)')) {
        const el = document.getElementById(id);
        el.style.transform = 'scale(1) rotateX(0) rotateY(0)';
        el.style.webkitTransform = 'scale(1) rotateX(0) rotateY(0)';
        el.style.transformStyle = 'flat';
        const container1 = document.getElementById('container-1');
        const string = 'translate3d(0, 0, 0)';
        container1.style.transform = string;
        container1.style.webkitTransform = string;
        container1.style.transformStyle = 'flat';
      }
    },
  },
};
</script>
<style lang="scss">
.banner-container {
    background: #03B4D7;
    padding: size(50) size(20);
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20);
    }
    @media screen and (min-width: $breakpoint-lg) {
     padding: size(140) size(0) size(80);
    }
    .common-wrapper {
      .image-wrapper {
        display: inline-block;
        vertical-align: top;
        position: relative;
        cursor: pointer;
        width: 90%;
        perspective: 100vw;
        transition: transform 1s ease;
        -webkit-transition: transform 1s ease;
        -moz-transition: transform 1s ease;
        -o-transition: transform 1s ease;
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        transform-style: flat;
        height: size(300);
        @media screen and (min-width: $breakpoint-md) {
          width: 55%;
          height: size(400);
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: 55%;
          height: size(700);
        }
        .image-item {
          height: size(300);
          position: absolute;
          top: 0;
          left: 10%;
          z-index: 5;
          @media screen and (min-width: $breakpoint-md) {
            height: size(400);
            left: 0;
          }
          @media screen and (min-width: $breakpoint-lg) {
            height: size(700);
          }
        }
      }
      #container-1 {
        display: inline-block;
        position: absolute;
        top: 20%;
        left: 20%;
        z-index: 10;
        padding: 0;
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        transform-style: flat;
        cursor: pointer;
        transition: transform 1.2s ease;
        -webkit-transition: transform 1.2s ease;
        -moz-transition: transform 1.2s ease;
        -o-transition: transform 1.2s ease;
        width: size(200);
        height: size(120);
        @media screen and (min-width: $breakpoint-md) {
          left: 10%;
          width: size(300);
          height: size(120);
        }
        @media screen and (min-width: $breakpoint-lg) {
          top: 30%;
          left: 0;
          width: size(561);
          height: size(168);
        }
      }
    }
    .content-wrapper {
      display: block;
      padding-top: size(40);
      @media screen  and (min-width: $breakpoint-md) {
        display: inline-block;
        vertical-align: top;
        margin-left: size(30);
      }
      @media screen and (min-width: $breakpoint-lg) {
        margin-left: size(80);
        padding-top: 0;
      }
      & > div {
        padding-bottom: size(20);
        margin-bottom: size(20);
        border-bottom: size(1) solid var(--rz-title-color);
        &:empty {
          padding-bottom: 0;
          border-bottom: 0;
          margin-bottom: 0;
        }
        &:last-child {
          padding-bottom: 0;
          border-bottom: 0;
          margin-bottom: 0;
        }
      }
      div {
        p {
          padding: 0 0 size(12);
          font-size: size(12);
          line-height: size(17);
          font-weight: 600;
          color: #014c5a;
        }
        &:last-child {
          p {
            padding: 0;
          }
        }
      }
      h1 {
        font-weight: 800;
        font-size: size(30);
        line-height: size(42);
        display: inline-block;
        max-width: size(250);
        color: var(--rz-copy-color);
        padding-bottom: size(30);
        @media screen and (min-width: $breakpoint-lg) {
          font-size: size(60);
          line-height: size(74);
          padding-bottom: size(60);
          padding-top: 0;
          max-width: size(365);
        }
      }
      h4 {
        font-size: size(18);
        line-height: size(25);
        font-weight: bold;
        padding-bottom: size(6);
      }
      a {
        display: inline-block;
        font-size: size(18);
        line-height: size(25);
        margin-bottom: size(6);
        text-decoration: none;
        transition: border 0.3s linear;
        color: var(--rz-copy-color);
        border-bottom: size(1) solid transparent;
        &:hover {
          border-bottom: size(1) solid var(--rz-copy-color);
        }
        &.support-link {
          margin-bottom: size(12);
        }
      }
    }
}
</style>
