<template>
  <div class="container">
    <div class="inner-wrapper" id="inner-wrapper">
      <div class="slideshow-container" id="slider-container">
        <div class="slides" v-for="item in section.detail.items" :key="item.key">
          <img :src="item.img.url" :alt="item.img.alt">
        </div>
      </div>
    </div>
    <div class="arrow-div">
      <span @click="moveLeft()" class="left-arrow">
        <svg><use v-bind="{'xlink:href':'#arrow-left'}"></use></svg>
      </span>
      <span @click="moveRight()" class="right-arrow">
        <svg><use v-bind="{'xlink:href':'#arrow-right'}"></use></svg>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    section: Object,
  },
  methods: {
    moveRight() {
      const container = document.getElementById('slider-container');
      const slides = document.getElementsByClassName('slides');
      const bounding = slides[slides.length - 1].getBoundingClientRect();
      if (!(bounding.right <= (window.innerWidth))) {
        container.style.left = `${container.offsetLeft - 100}px`;
      }
    },
    moveLeft() {
      const container = document.getElementById('slider-container');
      const slides = document.getElementsByClassName('slides');
      const bounding = slides[0].getBoundingClientRect();
      if (!(bounding.left >= 0)) {
        container.style.left = `${container.offsetLeft + 100}px`;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  @media screen and (min-width: $breakpoint-lg) {
    position: relative;
  }
  &:hover {
    .arrow-div {
      opacity: 1;
    }
  }
}
.arrow-div {
  text-align: center;
  width: 100%;
  display: none;
  position: absolute;
  z-index: 2;
  top: 45%;
  left: 0;
  right: 0;
  transform: translateY(-45%);
  transition: opacity 0.5s linear;
  @media screen and (min-width: $breakpoint-lg) {
    display: block;
    opacity: 0;
  }
  span {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    &.left-arrow {
      left: size(-10);
    }
    &.right-arrow {
      right: size(-10);
    }
    svg {
      width: size(40);
      height: size(40);
      cursor: pointer;
      fill: none;
    }
  }
}
</style>
