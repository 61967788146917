<template>
  <div class="rz-home" v-if="pageState.status.isLoaded && page">
    <div v-if="page.sections && page.sections.length">
      <section v-for="(section, index) in page.sections" :key="index">
        <section class="rz-hero-container" v-if="section.static_section_id == 'banner'">
          <HomePageBanner :section="section"/>
        </section>
        <section class="rz-logos-container" v-if="section.static_section_id == 'logos'">
          <div class="common-wrapper">
            <rz-image-item
              v-if="section.detail.img" :image="section.detail.img" />
          </div>
        </section>
        <section class="rz-heading-content" v-if="section.static_section_id == 'sports_tech_copy'">
          <div class="common-wrapper">
            <rz-markdown :text="section.detail.desc"></rz-markdown>
          </div>
        </section>
        <section id="products" class="rz-products-container" v-if="section.static_section_id == 'rz_products'">
          <div class="products common-wrapper">
            <div class="products-inner-wrapper">
              <div v-for="item in section.detail.items" :key="item.key">
                <rz-image-item
                  v-if="item.img" :image="item.img" />
                <h4>{{item.name}}</h4>
                <p>{{item.desc}}</p>
                <a :href="item.links[0].url">{{item.links[0].name}}
                  <svg><use v-bind="{'xlink:href':'#home-arrow-blue-1'}"></use></svg></a>
              </div>
            </div>
          </div>
        </section>
        <PromoBanner :section="section" v-if="section.static_section_id == 'ants_promo'"/>
        <section class="rz-push-config-container" v-if="section.static_section_id == 'home_dev_copy'">
          <div class="common-wrapper push-config">
            <div>
              <h3>{{section.detail.name}}</h3>
              <p>{{section.detail.desc}}</p>
              <rz-image-item
                v-if="section.detail.img" :image="section.detail.img" />
            </div>
          </div>
        </section>
        <section class="rz-heading-content" v-if="section.static_section_id == 'home_consulting'">
          <div class="common-wrapper">
            <rz-markdown :text="section.detail.desc"></rz-markdown>
          </div>
        </section>
        <section class="rz-consulting-list" v-if="section.static_section_id == 'consulting_list'">
          <div class="common-wrapper">
            <h5>Our Solutions</h5>
            <h4>{{section.detail.items[0].name}}</h4>
            <div class="list-wrapper container-1">
              <div class="content-wrapper">
                <p>{{section.detail.items[0].desc}}</p>
                <a class="btn-style" :href="section.detail.items[0].links[0].url">
                  {{section.detail.items[0].links[0].name}}
                  <svg><use v-bind="{'xlink:href':'#home-arrow'}"></use></svg></a>
              </div>
              <rz-image-item
                v-if="section.detail.items[0].img" :image="section.detail.items[0].img" />
            </div>
            <div class="list-wrapper"
            :class="'container-'+(index+1)"
            v-for="(item, index) in section.detail.items" :key="item.key">
              <template v-if="index > 0">
                <div class="content-wrapper">
                  <h4>{{item.name}}</h4>
                  <p>{{item.desc}}</p>
                  <a class="btn-style" v-if="item.links && item.links[0]"
                  :href="item.links[0].url">
                    {{item.links[0].name}}
                    <svg><use v-bind="{'xlink:href':'#home-arrow'}"></use></svg></a>
                </div>
                <rz-image-item
                  v-if="item.img" :image="item.img" />
              </template>
            </div>
          </div>
        </section>
        <section class="rz-newsroom-list" v-if="section.static_section_id == 'newsroom'">
          <div class="common-wrapper">
            <h4>Newsroom</h4>
            <div class="news-container-wrapper">
              <div :class="'news-container-'+index"
                v-for="(item , index) in section.detail.items" :key="item.key">
                <rz-image-item
                v-if="item.img" :image="item.img" />
                <h5>{{item.name}}</h5>
                <p><a :href="item.links[0].url">{{item.desc}}</a></p>
                <a :href="item.links[0].url">
                  {{item.links[0].name}}
                  <svg><use v-bind="{'xlink:href':'#home-arrow-blue-1'}"></use></svg></a>
              </div>
            </div>
            <!-- <a class="more-news" href="/newsroom">
              See More News
              <svg><use v-bind="{'xlink:href':'#home-arrow-blue-1'}"></use></svg></a> -->
          </div>
        </section>
        <section class="rz-join-us" v-if="section.static_section_id == 'openings'">
          <join-us-section :section="section"/>
        </section>
        <section class="rz-pre-footer" v-if="section.static_section_id == 'pre_footer'">
          <div class="common-wrapper">
            <pre-footer :section="section"/>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import JoinUsSection from '@/components/JoinUsSection.vue';
import PreFooter from '@/components/PreFooter.vue';
import HomePageBanner from '@/components/HomePageBanner.vue';
import PromoBanner from '@/components/PromoBanner.vue';

export default {
  name: 'rzHome',
  components: {
    JoinUsSection,
    PreFooter,
    HomePageBanner,
    PromoBanner,
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
    }),
  },
  serverPrefetch() {
    return this.fetchData();
  },
  data() {
    return {
      articles: null,
      limit: 10,
      pagesArray: [],
      currentPage: 1,
      totalpages: null,
      pageCount: 0,
      articleLoaded: false,
    };
  },
  mounted() {
    console.log('this.dataLoaded', this.dataLoaded);
    if (this.dataLoaded) {
      const sameRoute = this.page.route_value === `rz-${this.$route.meta.routeValue}`;
      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
        return;
      }
    }

    if (!this.dataLoaded) {
      this.freshFetch();
    }
  },
  methods: {
    freshFetch() {
      return this.fetchData();
    },
    fetchData() {
      const route = '/roanuz/pages/';
      const routeValue = `rz-${this.$route.meta.routeValue}`;
      return this.$store.dispatch('page/fetchActivePage', { route, routeValue });
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
    },
  },
};
</script>
<style lang="scss">
.rz-home {
  .common-wrapper {
    max-width: size(1080);
    margin: 0 auto;
  }
  .rz-logos-container {
    padding: size(30) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(40) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(60) 0 0;
    }
    .image-item {
      height: size(75);
    }
  }
  .rz-heading-content {
    .markdown {
      display: block;
      font-weight: 800;
      font-size: size(32);
      line-height: size(42);
      color: var(--rz-copy-color);
      padding-top: size(50);
      margin: 0 auto;
      padding: size(50) size(20) 0;
      @media screen and (min-width: $breakpoint-md) {
        padding: size(80) size(20) 0;
      }
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(60);
        line-height: size(74);
        padding: size(120) 0 0;
      }
      span {
        display: block;
        max-width: 100%;
      }
      em {
        a {
          color: var(--rz-btn-color);
          font-weight: 800;
        }
      }
    }
  }
  .rz-push-config-container {
    .image-item {
      border-radius: size(20);
      img {
        width: 100%;
        border-radius: size(20);
        height: size(250);
        object-fit: cover;
        @media screen and (min-width: $breakpoint-md) {
          height: size(400);
          max-width: size(680);
        }
        @media screen and (min-width: $breakpoint-lg) {
          height: size(600);
          max-width: 100%;
        }
      }
    }
  }
  .rz-pre-footer {
    padding: size(40) size(20) 0;
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(60) size(0) 0;
    }
  }
  .rz-consulting-list {
    .list-wrapper {
      &.container-1 {
        .image-item {
          padding-left: 0;
          padding-top: size(30);
          border-radius: size(10);
          text-align: left;
          height: size(250);
          padding-bottom: size(20);
          @media screen and (min-width: $breakpoint-md) {
            text-align: right;
            padding-top: 0;
            padding-left: size(50);
            height: size(265);
          }
          @media screen and (min-width: $breakpoint-lg) {
            padding-left: size(80);
            height: size(380);
          }
          .content-wrapper {
            @media screen and (min-width: $breakpoint-md) {
              flex: 0 0 48%;
            }
          }
          img {
            border-radius: size(10);
            object-fit: cover;
            width: 100%;
          }
        }
      }
      &.container-2, &.container-3 {
        flex-direction: row-reverse;
        padding-top: size(20);
        margin-top: size(20);
        border-top: size(1) dashed  #C4C4C4;
        @media screen and (min-width: $breakpoint-md) {
          padding-top: size(50);
          margin-top: 0;
          align-items: flex-end;
          border: none;
        }
        @media screen and (min-width: $breakpoint-lg) {
          padding-top: size(120);
        }
        .content-wrapper {
          @media screen and (min-width: $breakpoint-md) {
            flex: 0 0 48%;
          }
        }
        .image-item {
          padding-right: 0;
          padding-top: size(30);
          border-radius: size(10);
          text-align: left;
          height: size(388);
          @media screen and (min-width: $breakpoint-md) {
            padding-top: 0;
            padding-right: size(50);
            flex: auto;
            height: size(480);
          }
          @media screen and (min-width: $breakpoint-lg) {
            padding-right: size(80);
            height: size(580);
          }
          img {
            border-radius: size(10);
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
      &.container-3 {
        flex-direction: row;
        .image-item {
          text-align: left;
          @media screen and (min-width: $breakpoint-md) {
            text-align: right;
            padding-left: size(50);
            padding-right: 0;
            flex: 0 0 48%;
          }
          @media screen and (min-width: $breakpoint-lg) {
            padding-left: size(80);
          }
        }
        .content-wrapper {
          @media screen and (min-width: $breakpoint-md) {
            flex: auto;
          }
        }
      }
    }
  }
  .rz-newsroom-list {
    .news-container-wrapper {
      position: relative;
    }
    .news-container-0 {
      width: 100%;
      @media screen and (min-width: $breakpoint-md) {
        width: 46%;
      }
      @media screen and (min-width: $breakpoint-lg) {
        width: 46%;
      }
      h5 {
        color: #27AE60;
      }
      .image-item {
        height: size(280);
        border-radius: size(10);
        width: 100%;
        @media screen and (min-width: $breakpoint-md) {
          height: size(300);
          text-align: left;
        }
        @media screen and (min-width: $breakpoint-lg) {
          height: size(400);
        }
        img {
          display: inline-block;
          border-radius: size(10);
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .news-container-1, .news-container-3 {
      width: 100%;
      padding-top: size(40);
      @media screen and (min-width: $breakpoint-md) {
        position: absolute;
        top: size(60);
        right: size(20);
        width: 46%;
        padding-top: 0;
      }
      @media screen and (min-width: $breakpoint-lg) {
        top: size(80);
        right: 0;
      }
      h5 {
        color: #FF8282;
      }
      p {
        @media screen and (min-width: $breakpoint-md) {
          max-width: size(450)!important;
        }
      }
      .image-item {
        height: size(280);
        border-radius: size(10);
        width: 100%;
        @media screen and (min-width: $breakpoint-md) {
          height: size(300);
          text-align: left;
        }
        @media screen and (min-width: $breakpoint-lg) {
          height: size(400);
        }
        img {
          display: inline-block;
          border-radius: size(10);
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .news-container-3 {
      width: 100%;
      padding-top: size(40);
      @media screen and (min-width: $breakpoint-md) {
        position: absolute;
        top: 59%;
        right: size(20);
        width: 46%;
        padding-top: 0;
      }
      @media screen and (min-width: $breakpoint-lg) {
        top: size(740);
        right: 0;
      }
    }
    .news-container-2 {
      padding-top: size(40);
      width: 100%;
      @media screen and (min-width: $breakpoint-md) {
        width: 46%;
      }
      @media screen and (min-width: $breakpoint-lg) {
        width: 46%;
      }
      h5 {
        color: #BB6BD9;
      }
      .image-item {
        height: size(280);
        border-radius: size(10);
        @media screen and (min-width: $breakpoint-md) {
          height: size(300);
          text-align: left;
        }
        @media screen and (min-width: $breakpoint-lg) {
          height: size(400);
        }
        img {
          display: inline-block;
          border-radius: size(10);
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.rz-home {
  font-family: var(--rz-hero-font);
  .rz-hero-container {
    background: var(--rz-bg-color);
    padding: 0 size(20);
    position: relative;
    @media screen and (min-width: $breakpoint-md) {
      padding: 0 size(30);
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: 0;
    }
  }
  .btn-style {
    text-decoration: none;
    padding: size(16) size(20);
    background: var(--rz-btn-color);
    color: var(--rs-white-color);
    display: inline-block;
    border-radius: size(5);
    font-weight: bold;
    font-size: size(15);
    line-height: size(21);
    cursor: pointer;
    svg {
      display: inline-block;
      width: size(8);
      height: size(14);
      vertical-align: top;
      fill: transparent;
      margin-left: size(5);
      transition: transform 0.3s linear;
      margin-top: size(3);
    }
    &:hover {
      svg {
        transform: translate(#{size(4)});
      }
    }
  }
  .hero-section {
    .content-wrap {
      color: var(--rs-white-color);
      max-width: 100%;
      display: inline-block;
      vertical-align: top;
      @media screen and (min-width: $breakpoint-md) {
        max-width: size(300);
      }
      @media screen and (min-width: $breakpoint-lg) {
        max-width: size(450);
      }
      h1 {
        max-width: size(460);
        font-weight: 800;
        font-size: size(32);
        line-height: size(42);
        padding-bottom: size(20);
        padding-top: size(60);
        @media screen and (min-width: $breakpoint-lg) {
          font-size: size(60);
          line-height: size(74);
        }
      }
      p {
        font-weight: normal;
        font-size: size(22);
        line-height: size(32);
        padding-bottom: size(20);
      }
      a {
        width: size(110);
      }
    }
    .img-wrap {
      display: inline-block;
      width: 100%;
      height: size(300);
      position: relative;
      @media screen and (min-width: $breakpoint-md) {
        width: 40%;
        height: size(400);
      }
      @media screen and (min-width: $breakpoint-lg) {
        width: 52%;
        height: size(700);
      }
      .wheel-fig {
        display: inline-block;
        width: size(200);
        height: size(180);
        background: url('../../src/assets/rz-website/rz-bg-1.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        right: size(0);
        transition: transform 0.3s linear;
        -webkit-animation:spin 10s linear infinite;
        -moz-animation:spin 10s linear infinite;
        animation:spin 10s linear infinite;
        @media screen and (min-width: $breakpoint-md) {
          top: size(70);
          right: size(-100);
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: size(306);
          height: size(280);
          top: size(70);
          right: size(-120);
        }
      }
      @-moz-keyframes spin {
        100% {
          -moz-transform: rotate(360deg);
        }
      }
      @-webkit-keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
        }
      }
      @keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
          transform:rotate(360deg);
        }
      }
      .rectangle-fig {
        display: inline-block;
        width: size(180);
        height: size(180);
        background: url('../../src/assets/rz-website/rz-bg-2.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        bottom: size(0);
        transition: transform 0.4s linear;
        -webkit-animation:spin 8s linear infinite;
        -moz-animation:spin 8s linear infinite;
        animation:spin 8s linear infinite;
        @media screen and (min-width: $breakpoint-md) {
          left: size(0);
          bottom: size(20);
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: size(306);
          height: size(280);
          left: size(0);
          bottom: size(100);
        }
      }
      @-moz-keyframes spin {
        100% {
          -moz-transform: rotate(360deg);
        }
      }
      @-webkit-keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
        }
      }
      @keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
          transform:rotate(360deg);
        }
      }
    }
  }
  .rz-products-container {
    padding-top: size(50);
    @media screen and (min-width: $breakpoint-md) {
      padding-top: size(80);
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding-top: size(100);
    }
    .products-inner-wrapper {
      text-align: left;
      color: var(--rz-copy-color);
      div {
        margin: size(15) size(15) size(30);
        @media screen and (min-width: $breakpoint-md) {
          display: inline-block;
          vertical-align: top;
          width: calc((100% / 2) - #{size(40)});
          margin: size(20);
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: calc((100% / 3) - #{size(40)});
          vertical-align: top;
          margin: 0;
          &:nth-child(2) {
            margin-left: size(60);
            margin-right: size(60);
          }
        }
        .image-item {
          width: size(34);
          height: size(28);
          margin: 0 0 size(10);
          @media screen and (min-width: $breakpoint-lg) {
            text-align: left;
            margin: 0 0 size(20);
          }
        }
        h4 {
          font-size: size(24);
          line-height: size(34);
          padding-bottom: size(20);
          font-weight: 800;
          text-align: left;
        }
        p {
          font-size: size(18);
          line-height: size(28);
          padding-bottom: size(20);
          text-align: left;
        }
        a {
          text-decoration: none;
          font-weight: bold;
          font-size: size(15);
          line-height: size(21);
          color: #006AFF;
          display: inline-block;
          text-align: left;
          svg {
            display: inline-block;
            width: size(8);
            height: size(8);
            margin-left: size(5);
            fill: none;
            stroke: var(--rz-btn-color);
            transition: transform 0.3s linear;
          }
          &:hover {
            svg {
              transform: translate(#{size(4)});
            }
    }
        }
      }
    }
  }
  .rz-push-config-container {
    padding: size(50) size(20) size(0);
    text-align: center;
    color: var(--rz-copy-color);
    @media screen and (min-width: $breakpoint-lg) {
      padding: 0;
      padding-top: size(120)
    }
    h3 {
      font-weight: 800;
      font-size: size(32);
      line-height: size(42);
      text-align: center;
      padding-bottom: size(20);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(60);
        line-height: size(74);
      }
    }
    p {
      font-size: size(18);
      line-height: size(28);
      text-align: center;
      color: var(--rz-copy-color);
      padding-bottom: size(25);
      margin: 0 auto;
      max-width: size(900);
      margin: 0 auto;
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(30);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding-bottom: size(40);
      }
    }
  }
  .rz-consulting-list {
    padding: size(50) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(120) size(0) 0;
    }
    h5 {
      font-weight: bold;
      font-size: size(18);
      line-height: size(25);
      color: var(--rz-copy-color);
      text-align: left;
      padding-bottom: size(20);
    }
    h4 {
      font-style: normal;
      font-weight: 800;
      font-size: size(32);
      line-height: size(42);
      color: var(--rz-copy-color);
      padding-bottom: size(20);
      max-width: size(600);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(60);
        line-height: size(74);
      }
    }
    .list-wrapper {
      color: var(--rz-copy-color);
      @media screen and (min-width: $breakpoint-md) {
        display: flex;
        justify-content: space-between;
      }
      p {
        font-weight: normal;
        font-size: size(18);
        line-height: size(28);
        padding-bottom: size(20);
      }
      a {
        display: inline-block;
      }
    }
  }
  .rz-newsroom-list {
    padding-top: size(50);
    @media screen and (min-width: $breakpoint-md) {
      padding-top: size(80);
      padding-bottom: size(40);
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding-top: size(120);
      padding-bottom: size(24);
    }
    h4 {
      font-weight: 800;
      font-size: size(32);
      line-height: size(42);
      color: var(--rz-copy-color);
      padding: 0 size(20);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(60);
        line-height: size(74);
        padding: 0;
      }
    }
    a {
      text-decoration: none;
      font-weight: bold;
      font-size: size(15);
      line-height: size(21);
      color: #006AFF;
      display: inline-block;
      cursor: pointer;
      svg {
        display: inline-block;
        width: size(8);
        height: size(8);
        fill: none;
        stroke: var(--rz-btn-color);
        margin-left: size(5);
        transition: transform 0.3s linear;
      }
      &:hover {
        svg {
          transform: translate(#{size(4)});
        }
      }
      &.more-news {
        color: var(--rz-copy-color);
        padding: 0 size(20);
        margin-top: size(20);
        svg {
          stroke: var(--rz-copy-color);
        }
        @media screen and (min-width: $breakpoint-lg) {
          padding: 0;
          margin-top: size(40);
        }
      }
    }
    .news-container-wrapper {
      padding: size(30) size(20) 0;
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(40) 0 0;
      }
      h5 {
        font-weight: bold;
        font-size: size(15);
        line-height: size(21);
        padding-bottom: size(10);
        padding-top: size(15);
        @media screen and (min-width: $breakpoint-lg) {
          padding-top: size(20);
        }
      }
      p {
        a {
          font-weight: bold;
          font-size: size(24);
          line-height: size(30);
          color: var(--rz-copy-color);
          margin-bottom: size(20);
          max-width: size(600);
          @media screen and (min-width: $breakpoint-lg) {
            font-size: size(30);
            line-height: size(42);
          }
        }
      }
      .news-right-container {
        @media screen and (min-width: $breakpoint-md) {
          padding-left: size(40);
        }
        @media screen and (min-width: $breakpoint-lg) {
          padding-left: size(80);
        }
      }
    }
  }
}

</style>
